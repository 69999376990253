import { Toast } from '@capacitor/toast';
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonProgressBar, IonRow, useIonLoading } from '@ionic/react';
import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useHistory } from 'react-router';
import ActivitiesPieChart from '../components/ActivitiesPieChart';
import PrimaryToolbar from '../components/PrimaryToolbar';
import useFetch from '../hooks/useFetch';
import './StressScoresPage.css'
import track from 'react-tracking';

interface IStressScore {
  "accelerometers": string,
  "activities": string,
  "dateSubmitted": string,
  "gyroscopes": string,
  "heartrates": string,
  id: number,
  "participant_id": string,
  "perceived_stress_score": number,
  "question_eight_notes": string,
  "question_eight_points": number,
  "question_eight_response": string,
  "question_five_notes": string,
  "question_five_points": number,
  "question_five_response": string,
  "question_four_notes": string,
  "question_four_points": number,
  "question_four_response": string,
  "question_nine_notes": string,
  "question_nine_points": number,
  "question_nine_response": string,
  "question_one_notes": string,
  "question_one_points": number,
  "question_one_response": string,
  "question_seven_notes": string,
  "question_seven_points": number,
  "question_seven_response": string,
  "question_six_notes": string,
  "question_six_points": number,
  "question_six_response": string,
  "question_ten_notes": string,
  "question_ten_points": number,
  "question_ten_response": string,
  "question_three_notes": string,
  "question_three_points": number,
  "question_three_response": string,
  "question_two_notes": string,
  "question_two_points": number,
  "question_two_response": string,
  "recommendations_id": number,
  "recommendations_useful": true
}

const StressScoresPage: React.FC = () => {

  const [responses, isLoading, isError] = useFetch<IStressScore>("stress-scores")
  const [sortedResponses, setSortedResponses] = useState()
  const [present] = useIonLoading();
  const history = useHistory()
  // const [userId] = useAuth()

  function dayToString(day: number): string {
    switch (day) {
      case 1:
        return "Monday"
      case 2:
        return "Tuesday"
      case 3:
        return "Wednesday"
      case 4:
        return "Thursday"
      case 5:
        return "Friday"
      case 6:
        return "Saturday"
      default:
        return ""
    }
  }

  function dayToMonth(month: number): string {
    switch (month) {
      case 0:
        return "January"
      case 1:
        return "February"
      case 2:
        return "March"
      case 3:
        return "April"
      case 4:
        return "May"
      case 5:
        return "June"
      case 6:
        return "July"
      case 7:
        return "August"
      case 8:
        return "September"
      case 9:
        return "October"
      case 10:
        return "November"
      case 11:
        return "December"
      default:
        return ""
    }
  }

  useEffect(() => {
    if (isLoading) {
      present({ message: 'Loading stress scores', duration: 3000 })
      console.log(responses?.reverse())
    }

    if (isError) {
      Toast.show({ text: 'Error loading stress scores', duration: 'long' })
      history.push('/')
    }
  }, [isLoading, isError, responses])
  return (
    <IonPage>
      <IonHeader>
        <PrimaryToolbar title='Stress Scores'></PrimaryToolbar>

      </IonHeader>

      <IonContent fullscreen>

        <IonList>
          <IonListHeader>
            <IonLabel>
              This Week

            </IonLabel>
          </IonListHeader>



          {responses != undefined && [...responses].reverse().map((stressScore) => {
            return (
              <IonCard style={{ marginLeft: '2%', marginRight: '2%' }} >
                <IonCardHeader>
                  <IonCardTitle>
                    <IonLabel>{`${dayToString(new Date(stressScore.dateSubmitted).getDay())} ${dayToMonth(new Date(stressScore.dateSubmitted).getMonth())}  ${new Date(stressScore.dateSubmitted).getDate()}`}</IonLabel>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem lines='none'>

                    <IonGrid>
                      {/* <IonLabel>{stressScore.dateSubmitted}</IonLabel> */}
                      <IonProgressBar value={0.5} slot="start"></IonProgressBar>
                      <IonRow>

                        <IonCol >
                          <div className="progress-bar" style={{ width: 100, height: 100, marginLeft: 115, marginBottom: 10 }}>
                            <CircularProgressbar minValue={0} maxValue={40} value={stressScore.perceived_stress_score} text={stressScore.perceived_stress_score.toString()} />
                          </div>
                          {/* <IonLabel>Stress Score: 4</IonLabel> */}
                          {/* <IonLabel className="label">4</IonLabel> */}
                          <IonLabel style={{ marginLeft: 50 }}>You showed signs of low stress</IonLabel>
                        </IonCol>
                      </IonRow>
                      {/* <IonRow className="low-row">
                  <IonCol>Low Stress Activties</IonCol>
                  <IonCol size={'4'}>5</IonCol>
                </IonRow>
                <IonRow className="mod-row">
                  <IonCol>Moderate Stress Activites</IonCol>
                  <IonCol size={'4'}>5</IonCol>
                </IonRow>
                <IonRow className="high-row">
                  <IonCol>High Stress Activities</IonCol>
                  <IonCol size={'4'}>5</IonCol>
                </IonRow> */}
                      <IonRow>
                        <IonCol>
                          <ActivitiesPieChart activities={stressScore.activities} uid={""} startDate={stressScore.dateSubmitted}></ActivitiesPieChart>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonButton href={`/stress-score/${stressScore.id}`} expand="full">View Full Stress Score</IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>

                  </IonItem>
                </IonCardContent>
              </IonCard>
            )
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default track({
  page: 'Stress Scores Page',
},
  {
    dispatchOnMount: true,
  },)(StressScoresPage);

