
import axios from "axios";
import { useEffect, useState } from 'react';
import useAuth from './useAuth';

const useFetchGroupedActivites = <T>() => {
    const [responses, setResponses] = useState<T[] | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [userId] = useAuth()
    // const [present, dismiss] = useIonLoading()
    // const history = useHistory()


    useEffect(() => {
        // console.log(userId)
        setIsLoading(true)
        // present({ message: 'Loading activities...', duration: 3000 })
        if (userId != null) {
            axios.get(`${import.meta.env.VITE_API_URL}/activities/grouped?participantId=${userId!}&startTime=${new Date().toISOString()}`,).then((res) => {
                // dismiss()
                setIsLoading(false)
                setIsError(false)
                setResponses(res.data)
            }).catch((err) => {
                // dismiss()
                setIsLoading(false)
                setResponses(null)
                setIsError(true)

                // Toast.show({ text: 'Error loading activities', duration: 'long' })
                // history.push('/')
                console.error(err)
            });

        }


        return () => { };

    }, [userId]);

    return [responses, isLoading, isError] as const
};

export default useFetchGroupedActivites;