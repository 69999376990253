import { Toast } from "@capacitor/toast";
import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonRow, IonText, isPlatform, useIonViewWillEnter } from "@ionic/react"
import { refresh, watch } from "ionicons/icons";
import { useState } from "react";
import Echo from "../classes/EchoPlugin";
import "./WatchConnectionCard.css"

const WatchConnectionCard: React.FC = () => {

    const [isConnected, setIsConnected] = useState<boolean>(false)
    async function checkWatchConnection() {
        try {
            await Echo.watchHealthCheck()
            setIsConnected(true)
        } catch (error) {
            setIsConnected(false)
            Toast.show({ text: "Error establishing connection to watch!" })
        }
    }
    useIonViewWillEnter(() => {


        if (isPlatform("capacitor")) {
            checkWatchConnection()
        }

    })

    return (
        <IonCard id="watch-card">
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonIcon icon={watch} size="large" color="light"></IonIcon>

                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonText color="light">
                                <h2>Watch Status</h2>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {isPlatform("capacitor") &&
                                <div >
                                    <IonText slot="start" color="light"><h5>Watch: {isConnected ? "Connected" : "Disconneted"}</h5></IonText>

                                    {/* <IonIcon src={alert} slot="end"></IonIcon> */}

                                </div>
                            }
                            {!isPlatform("capacitor") && <div >
                                <IonText slot="start" color="light"><h5>Disabled on Web</h5></IonText>
                                {/* <IonIcon src={warning} slot="end" color="yellow" style={{ color: 'yellow' }}></IonIcon> */}
                                {/* <IonButton slot="end" fill="clear" shape="round" onClick={() => checkWatchConnection()}>Sync</IonButton> */}

                            </div>}
                        </IonCol>

                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton fill="outline" onClick={() => checkWatchConnection()}>Sync</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
                {/* <IonCardHeader> */}

                {/* </IonCardHeader> */}

            </IonCardContent>

        </IonCard>
    )
}

export default WatchConnectionCard;