import { Toast } from "@capacitor/toast";
import { IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonLoading, useIonViewWillLeave } from "@ionic/react"
import { useEffect } from "react";
import { useHistory } from "react-router";
import HeartRateChart from "../components/HeartRateChart";
import PrimaryToolbar from "../components/PrimaryToolbar";
import useEndScreenTime from "../hooks/useEndScreenTime";
import useFetchHeartAggregate from "../hooks/useFetchHeartAggregate";
import useStartScreenTime from "../hooks/useStartScreenTime";
import HeartratePageProps from "../wrappers/HeartratePageProps";

var currentDate = new Date();

function getCurrentDate(): Date {
  return new Date()
}

function dayToString(day: number) {
  switch (day) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      break;
  }
}

interface IHeartRateAggegate {
  minHeartrate: string
  maxHeartrate: string
  avgHeartrate: string
  timestamp: string
}

const HeartratePage: React.FC<HeartratePageProps> = ({ uid }) => {
  useStartScreenTime()
  const [setTriggered] = useEndScreenTime()
  const [responses, isLoading, isError] = useFetchHeartAggregate<IHeartRateAggegate>("heartrates/aggregations", new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 3, 0, 0, 0, 0).toISOString(), new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 0, 0, 0, 0).toISOString())
  const [present, dismiss] = useIonLoading()
  const history = useHistory()
  useIonViewWillLeave(() => {
    setTriggered(true)
  })

  useEffect(() => {
    if (isLoading) {
      present({ message: 'Loading heart rates', duration: 3000 })
    }

    if (isError) {
      dismiss()
      Toast.show({ text: 'Error loading heartrates', duration: 'long' })
      history.push('/')
    }
  }, [responses, isLoading, isError])


  return (
    <IonPage>
      <IonHeader >

        <PrimaryToolbar title='Heart Rate'></PrimaryToolbar>

      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Heart Rate</IonTitle>
            <IonButtons slot="start">

              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>


        <IonCard style={{ padding: '5%', marginLeft: '4%', marginRight: '4%' }}>
          <IonCardSubtitle>Today</IonCardSubtitle>
          <HeartRateChart uid={uid} startDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0).getTime()} endDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 24, 0, 0, 0).getTime()}></HeartRateChart>
        </IonCard>

        <IonList>

          {responses?.map((aggregate, index) => {
            return (
              <IonCard style={{ marginLeft: '4%', marginRight: '4%' }} >
                <IonCardHeader>
                  <IonGrid>
                    <IonRow>
                      <IonLabel>{dayToString(new Date(responses[responses.length - index - 1].timestamp).getDay())}</IonLabel>
                    </IonRow>
                    <IonRow style={{ marginLeft: '15%' }}>
                      <IonCol>
                        <IonCardTitle>

                          <IonText>{responses[responses.length - index - 1] !== undefined ? responses[responses.length - index - 1].minHeartrate : "-"}</IonText>
                        </IonCardTitle>
                        <IonCardSubtitle> Min</IonCardSubtitle>
                      </IonCol>
                      <IonCol>
                        <IonCardTitle>
                          <IonText>{responses[responses.length - index - 1] !== undefined ? responses[responses.length - index - 1].maxHeartrate : "-"}</IonText>
                        </IonCardTitle>
                        <IonCardSubtitle> Max</IonCardSubtitle>
                      </IonCol>
                      <IonCol>
                        <IonCardTitle>
                          <IonText>{responses[responses.length - index - 1] !== undefined ? responses[responses.length - index - 1].avgHeartrate : "-"}</IonText>
                        </IonCardTitle>
                        <IonCardSubtitle> Avg</IonCardSubtitle>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>

                <IonCardContent>
                  <HeartRateChart uid={uid} startDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (index + 1), 0, 0, 0, 0).getTime()} endDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (index + 1), 24, 0, 0, 0).getTime()}></HeartRateChart>
                </IonCardContent>

              </IonCard>
            )
          })}

          {/* <IonCard style={{ marginLeft: '4%', marginRight: '4%' }}>
            <IonCardHeader>
              <IonGrid>
                <IonRow style={{ marginLeft: '15%' }}>
                  <IonCol>
                    <IonCardTitle>
                      <IonText>{responses != null ? responses[1].minHeartrate : "-"}</IonText>
                    </IonCardTitle>
                    <IonCardSubtitle> Min</IonCardSubtitle>
                  </IonCol>
                  <IonCol>
                    <IonCardTitle>
                      <IonText>{responses != null ? responses[1].maxHeartrate : "-"}</IonText>
                    </IonCardTitle>
                    <IonCardSubtitle> Max</IonCardSubtitle>
                  </IonCol>
                  <IonCol>
                    <IonCardTitle>
                      <IonText>{responses != null ? responses[1].avgHeartrate : "-"}</IonText>
                    </IonCardTitle>
                    <IonCardSubtitle> Avg</IonCardSubtitle>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
            <IonCardContent>
              <HeartRateChart uid={uid} startDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2, 0, 0, 0, 0).getTime()} endDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2, 24, 0, 0, 0).getTime()}></HeartRateChart>
            </IonCardContent>

          </IonCard>
          <IonCard style={{ marginLeft: '4%', marginRight: '4%' }}>
            <IonCardHeader>
              <IonGrid>
                <IonRow style={{ marginLeft: '15%' }}>
                  <IonCol>
                    <IonCardTitle>
                      <IonText>{(responses != null && responses !== undefined) ? responses[2].minHeartrate : "-"}</IonText>
                    </IonCardTitle>
                    <IonCardSubtitle> Min</IonCardSubtitle>
                  </IonCol>
                  <IonCol>
                    <IonCardTitle>
                      <IonText>{(responses != null && responses !== undefined) ? responses[2].maxHeartrate : "-"}</IonText>
                    </IonCardTitle>
                    <IonCardSubtitle> Max</IonCardSubtitle>
                  </IonCol>
                  <IonCol>
                    <IonCardTitle>
                      <IonText>{(responses != null && responses !== undefined) ? responses[2].avgHeartrate : "-"}</IonText>
                    </IonCardTitle>
                    <IonCardSubtitle> Avg</IonCardSubtitle>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>
            <IonCardContent>
              <HeartRateChart uid={uid} startDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 3, 0, 0, 0, 0).getTime()} endDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 3, 24, 0, 0, 0).getTime()}></HeartRateChart>
            </IonCardContent>

          </IonCard> */}
          <IonItem>

            {/* <IonGrid>
                  <IonRow>
                      <IonCol size={'3'}>
                        {dayToString(getCurrentDate().getDay()-1)}
                      </IonCol>
                      <IonCol>
                            <IonLabel>62 bpm</IonLabel>
                            <IonLabel>Average</IonLabel>
                            <HeartRateChart uid={uid} startDate={new Date(currentDate.getFullYear(),currentDate.getMonth(),currentDate.getDate()-1,0,0,0,0).getTime()} endDate={new Date(currentDate.getFullYear(),currentDate.getMonth(),currentDate.getDate()-1,24,0,0,0).getTime()}></HeartRateChart>
                      </IonCol>
                  </IonRow>
              </IonGrid> */}
          </IonItem>
          {/* <IonItem>
            <IonGrid>
              <IonRow>
                <IonCol size={'3'}>
                  {dayToString(getCurrentDate().getDay() - 2)}
                </IonCol>
                <IonCol>
                  <p>70 bpm</p>
                  <p>Average</p>
                  <HeartRateChart uid={uid} startDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2, 0, 0, 0, 0).getTime()} endDate={new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2, 24, 0, 0, 0).getTime()}></HeartRateChart>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem> */}
        </IonList>
      </IonContent>
    </IonPage >
  )
}

export default HeartratePage;