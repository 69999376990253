import Axios from "axios"
import ActivityType from "../wrappers/ActivityType";
import IStressScore from "../wrappers/IStressScore"
import ScreenTimeType from "../wrappers/ScreenTimeType";
// import axios from "../utils/cache";


import { setupCache, buildWebStorage, } from "axios-cache-interceptor";

// var instance Axios.create()

const axios = setupCache(Axios, { debug: console.log, storage: buildWebStorage(localStorage, 'axios-cache:') });

interface HeartRate {
    participant_id: string
    bpm: number
    timestamp: Date
}

export function createStressScore(score: IStressScore) {
    return axios.post(`${import.meta.env.VITE_API_URL}/stress-scores`, score, {
        headers: {
            'Content-Type': 'application/json'
        }, cache: { update: { 'stress_scores': 'delete' } }
    })
}

export function getLatestStressScores(participantId: string) {
    return axios.get(`${import.meta.env.VITE_API_URL}/stress-scores/latest?participantID=${participantId}`, { cache: { interpretHeader: false }, id: `stress_scores` })
}

export async function getActivities(participantId: string, startTime: string, endTime: string): Promise<ActivityType[]> {

    return new Promise(async (resolve, reject) => {
        var newActivities: ActivityType[] = [];
        await axios.get(`${import.meta.env.VITE_API_URL}/activities?participantID=${participantId}&startTime=${startTime}&endTime=${endTime}`, { cache: { interpretHeader: false }, id: `activities` }).then((response) => {
            var returnedActivities = response.data;
            console.log(returnedActivities)
            returnedActivities.forEach((activity: { [x: string]: any; }) => {
                newActivities.push({ name: activity["name"], type: activity["type"], startTime: new Date(activity["startTime"]).toLocaleTimeString(), duration: activity["duration"], stressLevel: activity["stressLevel"], startDate: new Date(activity["startTime"]).getUTCDay() })
            });

            resolve(newActivities)
        }).catch((err) => {
            console.log(err)
            reject(err)
        })
    })


}

export async function getRecommendations(stressScoreId: string): Promise<[]> {
    return new Promise(async (resolve, reject) => {
        try {
            var recommendations = (await axios.get(`${import.meta.env.VITE_API_URL}/stress-scores/${stressScoreId}/recommendations`)).data
            console.log(recommendations)
            resolve(recommendations);
        } catch (error) {
            reject(error);
        }
    })
}

export async function getCurrentHeartRate(id: string): Promise<HeartRate> {
    return new Promise(async (resolve, reject) => {

        try {
            var heartrate: HeartRate = (await axios.get(`${import.meta.env.VITE_API_URL}/heartrates/current?participantID=${id}`, { headers: { 'Content-Type': 'application/json' }, cache: { interpretHeader: false }, id: id })).data
            // console.log(heartrate)
            resolve(heartrate)
        } catch (error) {
            reject(error)
        }
    })
}

export async function getStressScoreForId(id: string | undefined): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            //TODO: Create stress score object to store information
            var score = (await axios.get(`${import.meta.env.VITE_API_URL}/stress-scores/${id}`)).data
            console.log(score)
            resolve(score)
        } catch (error) {
            console.log(error)
            reject(error)

        }
    })
}

//TODO: Replace object with Heartrate Interface
export async function getHeartrates(uid: string, startDate: number, endDate: number): Promise<Object[]> {
    return new Promise(async (resolve, reject) => {
        try {
            var heartrates = (await axios.get(`${import.meta.env.VITE_API_URL}/heartrates?participantID=${uid}&starttime=${startDate}&endtime=${endDate}`)).data
            resolve(heartrates)
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

export async function createNewActivity(data: string, participantId: string): Promise<Object> {
    return new Promise(async (resolve, reject) => {
        try {
            var prop = `activities_${participantId}`
            var createdActivity = (await axios.post(`${import.meta.env.VITE_API_URL}/activities`, data, { headers: { 'Content-Type': 'application/json' }, cache: { update: { 'activities': 'delete' } } })).data

            resolve(createdActivity);
        } catch (error) {
            reject(error)
        }
    })
}

export async function submitScreenTime(data: ScreenTimeType): Promise<ScreenTimeType> {
    return new Promise(async (resolve, reject) => {
        try {
            var submittedTime: ScreenTimeType = (await axios.post(`${import.meta.env.VITE_API_URL}/devices/screen-time`, data, {
                headers: { 'Content-Type': 'application/json' }
            })).data
            resolve(submittedTime);

        } catch (error) {
            reject(error)
        }
    })
}
// module.exports = {
//     createStressScore:createStressScore,
//     getLatestStressScores:getLatestStressScores
// }

