import { registerPlugin } from '@capacitor/core';

export interface EchoPlugin {
  echo(options: { value: string, user: string }): Promise<{ value: string, user: string }>;
  showNativeCalendar(): Promise<{ timestamp: string }>
  showDialer(options: { number: string }): Promise<{}>
  updateToken(options: { token: string }): Promise<{}>
  login(options: { email: string, password: string }): Promise<{}>
  logout(): Promise<{}>
  watchHealthCheck(): Promise<{}>
}

const Echo = registerPlugin<EchoPlugin>('Echo');

export default Echo;