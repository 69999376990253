import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonPage, IonText, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { useState } from 'react';

import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router';
import ActivitiesPieChart from '../components/ActivitiesPieChart';
import useAuth from '../hooks/useAuth';
import { getRecommendations, getStressScoreForId } from '../services/StressAPIService';
import './PerceivedStressScorePage.css'
import track from 'react-tracking';

const PerceivedStressScorePage: React.FC = () => {

  let { id } = useParams<{ id?: string }>()
  const [score, setScore] = useState<any>({})
  const [recommendations, setRecommendations] = useState<[]>([])
  const [userId] = useAuth()




  useIonViewWillEnter(() => {
    async function getStressScore() {
      console.log(id)
      try {
        var score = await getStressScoreForId(id)
        setScore(score)
        console.log(score)
        var recommendations = await getRecommendations(id!);
        setRecommendations(recommendations)
      } catch (error) {
        console.error(error)
      }
    }

    if (userId != null) {
      getStressScore()
    }


  }, [userId, score])
  return (
    <IonPage >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Perceived Stress Score</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="stress-page" fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 2</IonTitle>
          </IonToolbar>
        </IonHeader> */}

        <div className="progress-bar" style={{ width: 200, height: 200, marginRight: '15%' }}>
          <CircularProgressbar styles={buildStyles({ trailColor: '#78909C', })} minValue={0} maxValue={40} value={score.perceived_stress_score} text={`${score.perceived_stress_score}`} />
        </div>
        {/* <IonItem> */}
        {/* <IonText><h5 style={{ textAlign: 'center' }}>You are showing signs of low stress</h5></IonText> */}
        {score.perceived_stress_score <= 13 && <IonText><h5 style={{ textAlign: 'center' }}>You are showing signs of low stress</h5></IonText>}
        {score.perceived_stress_score > 13 && score.perceived_stress_score < 27 && <IonText><h5 style={{ textAlign: 'center' }}>You are showing signs of moderate stress</h5></IonText>}
        {score.perceived_stress_score >= 27 && <IonText><h5 style={{ textAlign: 'center' }}>You are showing signs of high stress</h5></IonText>}

        {/* </IonItem> */}
        {/* <IonItem>
            <IonText>You are showing signs of low stress</IonText>
            
        </IonItem> */}
        {/* <IonItem>
        <ul>
                <li>Continue to follow your daily activities!</li>
                <li>Remember to refer to your coping strategies if you start to fill stressed</li>
            </ul>
        </IonItem> */}

        {recommendations.map((recommendation: any, index: number) => {
          return (
            <IonCard key={index}>
              <IonCardHeader>
                <IonCardSubtitle>{recommendation.title}</IonCardSubtitle>
                {/* <IonCardTitle>Keep up the good work</IonCardTitle> */}
              </IonCardHeader>

              <IonCardContent>
                {recommendation.description}
              </IonCardContent>
            </IonCard>
          )

        })}

        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Activies by Stress Level</IonCardSubtitle>
            {/* <IonCardTitle>Coping Strategies</IonCardTitle> */}
          </IonCardHeader>

          <IonCardContent>
            {/* {score.date_submitted} */}
            <ActivitiesPieChart uid={''} startDate={score.date_submitted} activities={score.activities} />
            {/* {score.date_submitted ? <ActivitiesPieChart uid={''} startDate={score.date_submitted} activities={score.activities} /> : <div>Loading...</div>} */}
          </IonCardContent>
        </IonCard>



      </IonContent>
    </IonPage>
  );
};

export default track({
  page: 'Perceived Stress Score Page',
},
  {
    dispatchOnMount: true,
  },)(PerceivedStressScorePage);

