import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';

// const data = {
//   labels: ['1 ', '2', '3', '4', '5', '6'],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       fill: false,
//       backgroundColor: 'rgb(255, 99, 132)',
//       borderColor: 'rgba(255, 99, 132, 0.2)',
//     },
//   ],
// };

// const options = {
//   scales: {
//     yAxes: [
//       {
//         ticks: {
//           beginAtZero: true,
//         },
//       },
//     ],
//   },
// };

// type DeepPartial<

// const scaleOptions:ScaleOptionsByType = {
//     type:"linear",

// }

// const typedOptions:ChartOptions = {
//     scales:scaleOptions,
// };


interface ActivityChartProps {
  uid: string,
  startDate: any,
  activities: string
}

function generateLabels() {

}



const ActivitiesPieChart: React.FC<ActivityChartProps> = ({ uid, startDate, activities }) => {

  // const [activities, setActivities] = useState<{ [k: string]: string }>()
  const [labels, setLabels] = useState<string[]>([])
  const [noStress, setNoStress] = useState<number>(0)
  const [lowStress, setLowStress] = useState<number>(0)
  const [modStress, setModStress] = useState<number>(0)
  const [highStress, setHighStress] = useState<number>(0)
  const chartRef = useRef();
  const [showNoStress, setShowNoStress] = useState<boolean>(false)
  const [showLowStress, setShowLowStress] = useState<boolean>(false)
  const [showModStress, setShowModStress] = useState<boolean>(false)
  const [showHighStress, setShowHighStress] = useState<boolean>(false)
  const [showChart, setShowChart] = useState<boolean>(true)
  const [noStressActivties, setNoStressActivities] = useState<String[]>([])
  const [lowStressActivities, setLowStressActivities] = useState<String[]>([])
  const [modStressActivities, setModStressActivities] = useState<String[]>([])
  const [highStressActivities, setHighStressActivities] = useState<String[]>([])
  // const data = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: 'Activties (beats per minute)',
  //       data: [],
  //       fill: false,
  //       backgroundColor: 'rgb(255, 99, 132)',
  //       borderColor: 'rgba(255, 0, 55, 0.72)',
  //       borderWidth: 1

  //     },

  //   ],
  // };

  // const onClick = (event: any) => {
  //   console.log(getDatasetAtEvent(chartRef.current, event));
  //   console.log(getElementAtEvent(chartRef.current, event));
  // }

  const data = {
    labels: ['No Stress', 'Low Stress', 'Moderate Stress', 'High Stress'],
    datasets: [
      {
        label: '# of Votes',
        data: [noStress, lowStress, modStress, highStress],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // useEffect(() => {
  //   async function getScores(){
  //     try {
  //       var history = (await getLatestStressScores()).data;
  //       var scores:number[] = []
  //       var dates:string[] = []
  //       for (let index = 0; index < history.length; index++) {
  //         const item:any = history[index];
  //         scores.push(item[0])
  //         dates.push(item[1])
  //       }

  //       var labels = []

  //       for (let index = 0; index < dates.length; index++) {
  //         const date = dates[index];
  //         labels.push(`${new Date(date).getMonth()}/${new Date(date).getDate()}`)
  //       }
  //       setLabels(labels)
  //       setScores(scores)
  //     } catch (error) {
  //         console.error(error)
  //       // setLabels([])
  //       // setScores([])
  //     }


  //   }

  //   getScores()
  // },[scores,labels])

  useEffect(() => {
    async function retrieveActivities() {
      try {
        console.log(startDate)
        var startTime = new Date(startDate);
        startTime.setDate(startTime.getDate() - 14)
        var endTime = new Date(startDate);
        console.log(startTime)
        console.log(endTime)
        // var retrievedActivities: ActivityType[] = await getActivities(uid, startTime.toISOString(), endTime.toISOString());
        // var activities: string[] = []
        console.log(activities)
        const retreivedActivities: { [k: string]: string } = JSON.parse(activities)
        // setActivities(retreivedActivities)
        console.log(retreivedActivities)
        var updatedActivities = []
        var updatedActivities1 = []
        var updatedActivities2 = []
        var updatedActivities3 = []

        for (const [key, value] of Object.entries(retreivedActivities)) {
          if (value === "None") {
            // var updatedActivities = noStressActivties
            updatedActivities.push(key)
            // setNoStressActivities(updatedActivities)
            // setNoStress(noStress + 1)
          }
          if (value === "Low") {
            // var updatedActivities1 = lowStressActivities
            updatedActivities1.push(key)
            // setLowStressActivities(updatedActivities1)
            // setLowStress(lowStress + 1)
          }

          if (value === "Moderate") {
            // var updatedActivities2 = modStressActivities
            updatedActivities2.push(key)
            // setModStressActivities(updatedActivities2)
            // setModStress(modStress + 1)
          }

          if (value === "High") {
            // var updatedActivities3 = highStressActivities
            updatedActivities3.push(key)
            // setHighStressActivities(updatedActivities3)
            // setHighStress(highStress + 1)
          }

          setNoStress(updatedActivities.length)
          setNoStressActivities(updatedActivities)

          setLowStress(updatedActivities1.length)
          setLowStressActivities(updatedActivities1)

          setModStress(updatedActivities2.length)
          setModStressActivities(updatedActivities2)

          setHighStress(updatedActivities3.length)
          setHighStressActivities(updatedActivities3)
          console.log(noStressActivties)
          console.log(lowStressActivities)
          console.log(modStressActivities)
          console.log(highStressActivities)
        }
        // var labels = []

        // setLabels(labels)
        // setActivities(activities)
      } catch (error) {
        console.error(error)
        // setLabels([])
        // setScores([])
      }


    }

    console.log(startDate)

    retrieveActivities()
  }, [startDate, uid, activities])

  return (
    <>
      <div className='header' style={{ width: 10 }}>

      </div>
      <div>
        {showChart && <Pie ref={chartRef} data={data} getDatasetAtEvent={(dateset, event) => {
          console.log(dateset)
          console.log(event)
        }} getElementAtEvent={(element: any, event) => {
          const category = element[0].index
          if (category === 0) {
            setShowChart(false)
            setShowNoStress(true)
          }

          if (category === 1) {
            setShowChart(false)
            setShowLowStress(true)
          }

          if (category === 2) {
            setShowChart(false)
            setShowModStress(true)
          }

          if (category === 3) {
            setShowChart(false)
            setShowHighStress(true)
          }
        }} />}

        {showNoStress && <IonList>
          {noStressActivties.map((activity) => {
            return (
              <IonItem>
                <IonLabel>{activity}</IonLabel>
              </IonItem>
            )

          })}
        </IonList>}

        {showHighStress && <IonList>
          {highStressActivities.map((activity) => {
            return (
              <IonItem>
                <IonLabel>{activity}</IonLabel>
              </IonItem>
            )
          })}
        </IonList>}

        {showLowStress && <IonList>
          {lowStressActivities.map((activity) => {
            return (
              <IonItem>
                <IonLabel>{activity}</IonLabel>
              </IonItem>
            )
          })}
        </IonList>}

        {showModStress && <IonList>
          {modStressActivities.map((activity) => {
            return (
              <IonItem>
                <IonLabel>{activity}</IonLabel>
              </IonItem>
            )
          })}
        </IonList>}

        {!showChart && <IonButton onClick={() => {
          setShowNoStress(false)
          setShowHighStress(false)
          setShowModStress(false)
          setShowLowStress(false)
          setShowChart(true)
        }}>Show Chart</IonButton>}

      </div>
    </>
  )
}
export default ActivitiesPieChart;

// function getDatasetAtEvent(current: undefined, event: React.MouseEvent<HTMLCanvasElement, MouseEvent>): any {
//   throw new Error('Function not implemented.');
// }
// function getElementAtEvent(current: undefined, event: React.MouseEvent<HTMLCanvasElement, MouseEvent>): any {
//   throw new Error('Function not implemented.');
// }

