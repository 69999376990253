import { useEffect, useState } from 'react';
import { Storage } from '@ionic/storage';
import { useHistory } from 'react-router';
import { submitScreenTime } from '../services/StressAPIService';
import ScreenTimeType from '../wrappers/ScreenTimeType';
import useAuth from './useAuth';

const useEndScreenTime = () => {
    // const [responses, setResponses] = useState<T[] | null>();
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    //     const [isError, setIsError] = useState<boolean>(false);
    const [triggered, setTriggered] = useState<boolean>(false);
    const history = useHistory()
    const [userId] = useAuth()
    const store = new Storage({
        name: '__screen_time'
    });
    async function createStorage() {

        await store.create();
    }

    useEffect(() => {

        if (triggered) {
            createStorage().then(() => {
                store.keys().then((keys) => {
                    if (keys.length > 0) {
                        const prevPath = keys[0];
                        store.get(prevPath).then((val) => {
                            let endTime = new Date().toISOString()
                            let startTime = val
                            console.log(prevPath)
                            console.log(startTime)
                            console.log(endTime)
                            let screenTime: ScreenTimeType = {
                                startTime: startTime,
                                endTime: endTime,
                                participantId: userId!,
                                page: prevPath
                            }
                            submitScreenTime(screenTime).then((val) => {
                                console.log(val)
                                store.remove(prevPath)
                            }).catch((err) => {
                                console.error(err)
                                store.remove(prevPath)
                            })

                        })

                        //TODO: Submit query to back end server
                    } else {
                        console.log('no previous page')
                    }

                })
            })
        }

        // history.location.


        // setIsLoading(true);
        // axios
        //     .get(`http://jsonplaceholder.typicode.com/${url}`)
        //     .then((resp) => {
        //         setIsLoading(false);
        //         setIsError(false);
        //         setResponses(resp.data);
        //     })
        //     .catch((err) => {
        //         setIsLoading(false);
        //         setIsError(true);
        //         setResponses(null);
        //     });
        // return () => { };
    }, [triggered]);

    return [setTriggered] as const;
};

export default useEndScreenTime;