import { IonCheckbox, IonContent, IonItem, IonLabel, IonList, IonPage } from "@ionic/react"

const OptOutPage: React.FC = () => {
    return (
        <IonPage>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonCheckbox checked slot="start"></IonCheckbox>
                        <IonLabel>Heart Rate</IonLabel>

                    </IonItem>
                    <IonList style={{ marginLeft: '5%' }}>
                        <IonItem>
                            <IonCheckbox checked slot="start"></IonCheckbox>
                            <IonLabel>Collecting Heart Rate from Smart Watch</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox checked slot="start"></IonCheckbox>
                            <IonLabel>Share with Case Specialist</IonLabel>
                        </IonItem>
                    </IonList>
                    <IonItem>
                        <IonCheckbox checked slot="start"></IonCheckbox>
                        <IonLabel>Stress</IonLabel>

                    </IonItem>
                    <IonList style={{ marginLeft: '5%' }}>
                        <IonItem>
                            <IonCheckbox checked slot="start"></IonCheckbox>
                            <IonLabel>Stress Survey Reminders</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox checked slot="start"></IonCheckbox>
                            <IonLabel>Share with Case Specialist</IonLabel>
                        </IonItem>
                    </IonList>
                    <IonItem>
                        <IonCheckbox checked slot="start"></IonCheckbox>
                        <IonLabel>Activities</IonLabel>

                    </IonItem>
                    <IonList style={{ marginLeft: '5%' }}>
                        <IonItem>
                            <IonCheckbox checked slot="start"></IonCheckbox>
                            <IonLabel>Stress Survey Reminders</IonLabel>

                        </IonItem>
                        <IonItem>
                            <IonCheckbox checked slot="start"></IonCheckbox>
                            <IonLabel>Share with Case Specialist</IonLabel>
                        </IonItem>
                    </IonList>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default OptOutPage;