import { IonCol, IonImg, IonRow, IonSkeletonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { convertDay } from "../utils/utils";
import ActivityProps from "../wrappers/ActivityProps";

const SubActivityContainer: React.FC<ActivityProps> = (props) => {

    const [isLoading, setIsLoading] = useState<boolean>(true)
    function convertToHours(duration: number): number {

        return duration / 60
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    })

    return (
        <IonRow>
            {isLoading ? (<><IonCol size="1">
                <IonImg className="list-icon" src="https://fonts.gstatic.com/s/i/materialiconsoutlined/adjust/v12/24px.svg" />
            </IonCol><IonCol>

                    <IonSkeletonText animated />
                </IonCol><IonCol>
                    <IonSkeletonText animated />
                </IonCol><IonCol>
                    {/* {props.activities.duration} minutes */}
                    <IonSkeletonText animated />
                </IonCol></>) : (<><IonCol size="1">
                    <IonImg className="list-icon" src="https://fonts.gstatic.com/s/i/materialiconsoutlined/adjust/v12/24px.svg" />
                </IonCol><IonCol>
                        {props.activities.name}
                    </IonCol>
                    <IonCol>
                        {convertDay(props.activities.startDate!)}
                    </IonCol>
                    <IonCol>
                        {props.activities.startTime}
                    </IonCol><IonCol>
                        {/* {props.activities.duration} minutes */}
                        {(props.activities.duration >= 60) ? convertToHours(props.activities.duration) : props.activities.duration} {(props.activities.duration >= 60) ? "hours" : "minutes"}
                    </IonCol>
                </>)}

        </IonRow>
    )
}

export default SubActivityContainer;