import axios from 'axios';
import { ChartOptions } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import useAuth from '../hooks/useAuth';
import { getHeartrates } from '../services/StressAPIService';
import HeartrateChartProps from '../wrappers/HeartrateChartProps';

// const data = {
//   labels: ['1 ', '2', '3', '4', '5', '6'],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       fill: false,
//       backgroundColor: 'rgb(255, 99, 132)',
//       borderColor: 'rgba(255, 99, 132, 0.2)',
//     },
//   ],
// };

// const options = {
//   scales: {
//     yAxes: [
//       {
//         ticks: {
//           beginAtZero: true,
//         },
//       },
//     ],
//   },
// };

// type DeepPartial<

// const scaleOptions:ScaleOptionsByType = {
//     type:"linear",

// }

// const typedOptions:ChartOptions = {
//     scales:scaleOptions,
// };

function generateLabels() {

}



const HeartRateChart: React.FC<HeartrateChartProps> = ({ uid, startDate, endDate }) => {

  const [scores, setScores] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])
  const [userId, setUserId, loggedIn, token] = useAuth()
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Heart Rate (beats per minute)',
        data: scores,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 0, 55, 0.72)',
        borderWidth: 1

      },

    ],
  };

  const options: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    }
  }

  // useEffect(() => {
  //   async function getScores(){
  //     try {
  //       var history = (await getLatestStressScores()).data;
  //       var scores:number[] = []
  //       var dates:string[] = []
  //       for (let index = 0; index < history.length; index++) {
  //         const item:any = history[index];
  //         scores.push(item[0])
  //         dates.push(item[1])
  //       }

  //       var labels = []

  //       for (let index = 0; index < dates.length; index++) {
  //         const date = dates[index];
  //         labels.push(`${new Date(date).getMonth()}/${new Date(date).getDate()}`)
  //       }
  //       setLabels(labels)
  //       setScores(scores)
  //     } catch (error) {
  //         console.error(error)
  //       // setLabels([])
  //       // setScores([])
  //     }


  //   }

  //   getScores()
  // },[scores,labels])

  function convertHours(hours: number): number {
    if (hours > 12) {
      return hours - 12
    } else {
      return hours
    }
  }
  async function getHeartrates(uid: string, startDate: number, endDate: number): Promise<Object[]> {
    return new Promise(async (resolve, reject) => {
      try {
        var heartrates = (await axios.get(`${import.meta.env.VITE_API_URL}/heartrates?participantID=${uid}&starttime=${startDate}&endtime=${endDate}`)).data
        resolve(heartrates)
      } catch (error) {
        console.log(error)
        reject(error)
      }
    })
  }

  useEffect(() => {
    async function getScores() {
      try {
        // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        var heartrates = await getHeartrates(uid, startDate, endDate);
        var scores: number[] = []
        var dates: string[] = []
        for (let index = 0; index < heartrates.length; index++) {
          const item: any = heartrates[index];
          if (`${new Date(item["timestamp"]).getFullYear()}` === '2023' || `${new Date(item["timestamp"]).getFullYear()}` === '2024') {
            // if(!dates.includes(item["timestamp"])){
            scores.push(item["bpm"])
            dates.push(item["timestamp"])
            // }

          }

        }

        var labels = []

        for (let index = 0; index < dates.length; index++) {
          const date = dates[index];
          const localDate = new Date(date).toLocaleString()
          labels.push(`${convertHours(new Date(localDate).getHours())}${new Date(localDate).getHours()
            > 12 ? "PM" : "AM"}`)


        }

        var updatedLabels = ["7AM", "8AM", "9AM", "10AM", "11AM", "12AM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM"]
        // var updatedLabels = ["9PM", "10PM", "11PM", "12PM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "6PM", "7PM", "8PM"]

        var updatedScores = []

        for (let index = 0; index < updatedLabels.length; index++) {
          const label = updatedLabels[index];
          var rate = 0
          var count = 0
          for (let index = 0; index < heartrates.length; index++) {
            const element = scores[index];
            if (`${convertHours(new Date(dates[index]).getHours())}${new Date(dates[index]).getHours()
              > 12 ? "PM" : "AM"}` === label) {
              rate = rate + element
              count = count + 1
            }

          }

          rate = rate / count
          updatedScores.push(rate)
        }


        setLabels(updatedLabels)
        setScores(updatedScores)
      } catch (error) {
        console.error(error)
        // setLabels([])
        // setScores([])
      }


    }

    getScores()
  }, [token])

  return (
    <>
      <div className='header' style={{ width: 10 }}>

      </div>
      <div>
        <Line data={data} />
      </div>
    </>
  )
}
export default HeartRateChart;