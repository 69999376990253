import { IonGrid, IonItem, IonLabel } from "@ionic/react";
import { useState } from "react";
import SubActivityListProps from "../wrappers/SubActivityListProps";
import SubActivityContainer from "./SubActivityContainer";

const SubActivityList: React.FC<SubActivityListProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    return (

        <div>
            <IonItem color="primary">
                <IonLabel>{props.type}</IonLabel>
            </IonItem>
            <IonGrid>
                {

                    props.activities.map((activity, index) => {

                        if (activity.type.toLowerCase() === props.type.toLowerCase()) {
                            return (

                                <SubActivityContainer key={index} activities={activity} />
                            )
                        }


                    })
                }
            </IonGrid>
        </div>
    )
}

export default SubActivityList;