import { IonContent, IonHeader, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react"

const NotificationsPage: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Notifications</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle>Notifications</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonList>
                    <IonListHeader>
                        <IonLabel>
                            <h1>This Week</h1>
                        </IonLabel>
                    </IonListHeader>
                    <IonItemSliding>
                        <IonItemOptions side="start">
                            <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
                            <IonItemOption color="danger" onClick={() => console.log('share clicked')}>Share</IonItemOption>
                        </IonItemOptions>

                        <IonItem>
                            <IonLabel>
                                <h2>Stress Survey Reminder</h2>
                                <p>Don't forget to fill out your bi-weekly stress survey</p>
                            </IonLabel>
                        </IonItem>

                        <IonItemOptions side="end">
                            <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                    <IonItemSliding>
                        <IonItemOptions side="start">
                            <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
                            <IonItemOption color="danger" onClick={() => console.log('share clicked')}>Share</IonItemOption>
                        </IonItemOptions>

                        <IonItem>
                            <IonLabel>
                                <h2>"Super Active" Badge</h2>
                                <p>You've logged over 20 activities this week! Keep up the good work</p>
                            </IonLabel>
                        </IonItem>

                        <IonItemOptions side="end">
                            <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                    <IonItemSliding>
                        <IonItemOptions side="start">
                            <IonItemOption onClick={() => console.log('favorite clicked')}>Favorite</IonItemOption>
                            <IonItemOption color="danger" onClick={() => console.log('share clicked')}>Share</IonItemOption>
                        </IonItemOptions>

                        <IonItem>
                            <IonLabel>
                                <h2>Recommendations Feedback</h2>
                                <p>Did you find your stress recommendations helpful?</p>
                            </IonLabel>
                        </IonItem>

                        <IonItemOptions side="end">
                            <IonItemOption onClick={() => console.log('unread clicked')}>Unread</IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default NotificationsPage;