import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonMenuButton, IonPopover, IonContent } from "@ionic/react"
import { notificationsOutline, refreshCircleOutline, refreshOutline, syncOutline } from "ionicons/icons"
import { useHistory } from "react-router"

const PrimaryToolbar: React.FC<{ title: string }> = ({ title }) => {

    const history = useHistory()

    function navigateToNotifications() {
        history.push("/notifications")
    }

    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot='start'>
                    {/* <IonItem routerLink="/account">
                        <IonAvatar style={{ width: '10', height: '10' }}>
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                        </IonAvatar>
                    </IonItem> */}
                    <IonMenuButton />
                </IonButtons>

                <IonButtons slot='end' >
                    {/* <IonButton id="notification-trigger" color='light' routerLink="/notifications" routerDirection="forward">
                        <IonIcon icon={notificationsOutline} size='large'></IonIcon>
                    </IonButton> */}
                    <IonPopover trigger="notification-trigger" triggerAction="context-menu">
                        <IonContent class="ion-padding">Notifications</IonContent>
                    </IonPopover>
                    <IonButton id="refresh-trigger" color='light' href={`${history.location.pathname}`}>
                        <IonIcon icon={syncOutline} size='large'></IonIcon>
                    </IonButton>
                    <IonPopover trigger="refresh-trigger" triggerAction="context-menu">
                        <IonContent class="ion-padding">Refresh</IonContent>
                    </IonPopover>
                </IonButtons>
                <IonTitle>{title}</IonTitle>
            </IonToolbar>
        </IonHeader >
    )
}

export default PrimaryToolbar;