import { IonCol, IonGrid, IonImg, IonItem, IonLabel, IonRow } from "@ionic/react";
import ActivityProps from "../wrappers/ActivityListProps";
import './ActivityList.css'
import SubActivityContainer from "./SubActivityContainer";
import SubActivityList from "./SubActivityList";
const ActivityList: React.FC<ActivityProps> = (props) => {
    //TODO: Consider storing these types in a config file instead so that code doesn't have to be changed
    const activity_types = ["Recreational", "Health", "Work", "Coping", "Other"];


    return (
        <div>

            {activity_types.map((type, index) => {
                return (
                    <SubActivityList key={index} activities={props.acitvites} type={type} />
                )


            })}
        </div>
    )
}

export default ActivityList;