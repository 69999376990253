import axios from "axios";
import { getAuth, User, UserCredential } from "firebase/auth";
import React, { useEffect, useState, createContext } from "react";
import { AuthContextType } from "../@types/auth";

export const AuthContext = createContext<AuthContextType | null>(null)

const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [userId, setUserId] = useState<string | null>(null)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [token, setToken] = useState<string>("")
    const [user, setUser] = useState<User | null>(null)
    useEffect(() => {
        const auth = getAuth()

        auth.onAuthStateChanged(async (user) => {

            if (user != null) {
                // console.log(user.getIdToken())
                axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
                setUserId(user.uid)
                setToken(await user.getIdToken())
            }

        })

        auth.onIdTokenChanged(async (user) => {
            if (user != null) {
                // console.log(await user.getIdToken())
                axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
                setUserId(user.uid)
                setToken(await user.getIdToken())
            }

        })

        // do {
        //     setInterval(() => {console.log('verifying auth')},5000)
        // } while (userId === null);
        console.log(auth.currentUser)
        if (auth.currentUser != null) {
            setLoggedIn(true)
            setUserId(auth.currentUser.uid)
        }

        setUser(auth.currentUser)
    })
    return (
        <AuthContext.Provider value={{ token, user }}>
            {children}
        </AuthContext.Provider>
    )
}


export default AuthProvider;
