import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
  useIonRouter,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { body, heart, home, medical } from 'ionicons/icons';
import { setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';
import HealthDashboard from './pages/HealthDashboard';
import NewActivityPage from './pages/NewActivityPage';
import StressQuestionPage from './pages/StressQuestionPage';
import ActivityStressPage from './components/ActivityStressPage';
import StressScoresPage from './pages/StressScoresPage';
import PerceivedStressScorePage from './pages/PerceivedStressScorePage';
import HeartratePage from './pages/HeartratePage';

import { initializeApp } from 'firebase/app';
import { useContext, useEffect, useState } from 'react';
import { getAuth, User } from '@firebase/auth';
import LoginPage from './pages/LoginPage';
import axios from 'axios';
import Echo from './classes/EchoPlugin';
import NotificationsPage from './pages/NotificationsPage';
import AccountPage from './pages/AccountPage';
import OptOutPage from './pages/OptOutPage';
import useNotice from './hooks/useNotice';
import useAuth from './hooks/useAuth';
import DocumentationPage from './pages/DocumentationPage';
import ActivitiesPage from './pages/ActivitiesPage';

// import React from 'react';

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import ChatPage from './pages/RevisedChatPage';
import AuthProvider, { AuthContext } from './context/authContext';
import { AuthContextType } from './@types/auth';
import React from 'react';
import ProtectedRoute from './components/ProtectRoute';

setupIonicReact({
  mode: 'md'
});

Sentry.init({
  dsn: "https://3f53c74346254c82a222bf4b02349d24@o1242712.ingest.sentry.io/6406939",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "nij-health-app@" + import.meta.env.VITE_HEALTH_APP_VERSION,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV

});




// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  Sentry.setExtra('error', error)
  Sentry.captureException(error);
  return Promise.reject(error);
});

const App: React.FC = () => {

  const [user, setUser] = useState<User | null>(null);
  const router = useIonRouter();
  useNotice()
  const [userId, setUserId, isLoggedIn] = useAuth()
  // let history = useHistory()

  // LocalNotifications.requestPermissions()




  useEffect(() => {
    // Sentry.captureMessage("My error message")
    // throw Error("my error ")
    async function getToken(user: User) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
    }

    getAuth().onIdTokenChanged(async (user) => {
      if (user) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`

        if (isPlatform("android") && !isPlatform("mobileweb")) {
          Echo.updateToken({ token: await user.getIdToken() })
        }

        // setUserId(user.uid);
      }

    })
    getAuth().onAuthStateChanged((user) => {
      // console.log('state change')
      // console.log(user)
      if (user != null) {
        getToken(user)
        setUser(user);
        Sentry.setUser({ id: user.uid, email: user.email! })



      } else {
        // history.push('/')

      }

      async function startBackendClient() {
        const auth = getAuth()

        if (auth.currentUser != null) {
          const currentUser = auth.currentUser;
          console.log('signed in')
          const token = await currentUser.getIdToken();
          const id = await currentUser.uid;
          // console.log(token)
          if (isPlatform("android")) {
            try {
              // await new Promise(r => setTimeout(r, 10000));
              const { value } = await Echo.echo({ value: token, user: id });
              console.log('Response from native:', value);
            } catch (error) {
              console.error(error);
            }
          }



        }
      }

      startBackendClient()

      if (import.meta.env.VITE_DISABLE_VERIFIER === "true") {
        getAuth().settings.appVerificationDisabledForTesting = true
      }


    })
  }, [user])

  return (

    <IonApp>
      {/* <CustomFabButton /> */}
      <AuthProvider>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/heartrates">
                {user === null ? <LoginPage /> : <HeartratePage uid={user.uid} />}
              </Route>
              <Route exact path="/stress-score/:id">
                {user === null ? <LoginPage /> : <PerceivedStressScorePage />}

              </Route>
              <Route exact path="/stress/scores">
                {user === null ? <LoginPage /> : <StressScoresPage />}
              </Route>
              <Route exact path="/stress/survey">
                {user === null ? <LoginPage /> : <StressQuestionPage uid={user?.uid} />}
              </Route>

              <Route exact path="/stress/activities">
                {user === null ? <LoginPage /> : <ActivityStressPage uid={user?.uid} />}
              </Route>
              <Route exact path="/activities/create">
                {user === null ? <LoginPage /> : <NewActivityPage uid={user.uid} />}
              </Route>
              <Route exact path="/home">
                <ProtectedRoute title='' />
              </Route>
              <Route exact path="/notifications">
                {user === null ? <LoginPage /> : <NotificationsPage />}
              </Route>
              <Route exact path="/account">
                {user === null ? <LoginPage /> : <AccountPage />}
              </Route>
              <Route exact path="/opt-out">
                {user === null ? <LoginPage /> : <OptOutPage />}
              </Route>
              <Route exact path="/documentation">
                {user === null ? <LoginPage /> : <DocumentationPage />}
              </Route>
              <Route exact path="/activities">
                {user === null ? <LoginPage /> : <ActivitiesPage />}
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />              </Route>
              <Route exact path="/chat">
                <ChatPage />
              </Route>
              <Route path="/login" exact>
                <LoginPage />
              </Route>
            </IonRouterOutlet>
            <IonTabBar color='primary' slot="bottom">
              {/* <IonTabButton tab="tab1" href="/tab1">
            <IonIcon icon={triangle} />
            <IonLabel>Tab 1</IonLabel>
          </IonTabButton> */}
              <IonTabButton tab="home" href="/home">
                <IonIcon icon={home} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>
              <IonTabButton tab="heartrate" href='/heartrates'>
                <IonIcon icon={heart} />
                <IonLabel>Heart Rate</IonLabel>
              </IonTabButton>
              <IonTabButton tab="stress" href='/stress/scores'>
                <IonIcon icon={medical} />
                <IonLabel>Stress</IonLabel>
              </IonTabButton>
              <IonTabButton tab="activities" href='/activities'>
                <IonIcon icon={body} />
                <IonLabel>Activities</IonLabel>
              </IonTabButton>
              {/* <IonTabButton tab="tab3" href="/tab3">
            <IonIcon icon={square} />
            <IonLabel>Tab 3</IonLabel>
          </IonTabButton> */}
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </AuthProvider>
    </IonApp>
  );
};

export default App;
