
import axios from 'axios';
import { useEffect, useState } from 'react';
import useAuth from './useAuth';

const useFetchHeartAggregate = <T>(url: string,startTime: string,endTime:string) => {
    const [responses, setResponses] = useState<T[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
        const [isError, setIsError] = useState<boolean>(false);
            const [userId] = useAuth()


    useEffect(() => {
                console.log(userId)
        setIsLoading(true)

            if (userId != null) {
                axios.get(`${import.meta.env.VITE_API_URL}/${url}?participantId=${userId!}&starttime=${startTime}&endtime=${endTime}`).then((res) => {
                    setIsLoading(false)
                    setIsError(false)
                    setResponses(res.data)
                }).catch((err) => {
                    setIsLoading(false)
                    setResponses([])
                    setIsError(true)
                    console.error(err)
                });
        
        }

        
        return () => { };

    }, [url,userId]);

            return [responses, isLoading, isError] as const
};

            export default useFetchHeartAggregate;