import  Axios  from "axios"
import TrackingActionType from "../types/TrackingActionType"
import TrackingType from "../types/TrackingPageType";

async function pushTracking(data: TrackingType): Promise<TrackingType|null> {
    try {
            var response:TrackingType = (await Axios.post(`${import.meta.env.VITE_TRACKING_API}/visits`,data)).data
            return response
    } catch (err) {
        console.error(err)
        return null
    }
}

async function pushAction(data: TrackingActionType): Promise<TrackingActionType | null> {
    try {
        var response: TrackingActionType = (await Axios.post(`${import.meta.env.VITE_TRACKING_API}/actions`, data,)).data        
        return response
    } catch (error) {
        console.error(error)
        return null
    }
}

export {
    pushTracking,
    pushAction
}