import { IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonItem, IonList, IonMenu, IonPage, IonRow, IonText, IonTitle, IonToolbar, isPlatform, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { useEffect, useState } from 'react';
import ActivityContainer from '../components/ActivityContainer';
import LineChart from '../components/DynamicChart';
import HeartrateDetectionContainer from '../components/HeartrateDectectionContainer';
import StressManagementContainer from '../components/StressManagementContainer';
import HeartRateType from '../wrappers/HeartRateType';


import './Tab1.css';
import Echo from '../classes/EchoPlugin';
import { getAuth, signOut } from "firebase/auth";
import { LocalNotifications } from '@capacitor/local-notifications';
import { useHistory } from 'react-router';
import PrimaryToolbar from '../components/PrimaryToolbar';
import useStartScreenTime from '../hooks/useStartScreenTime';
import useEndScreenTime from '../hooks/useEndScreenTime';
import { Toast } from '@capacitor/toast';
import useAuth from '../hooks/useAuth';
import WatchConnectionCard from '../components/WatchConnectionCard';
import track, { useTracking } from "react-tracking";
import NotificationType from '../types/NotificationType';
import { PushNotifications } from '@capacitor/push-notifications';
import { pushAction } from '../api/api';
import TrackingActionType from '../types/TrackingActionType';
import { get } from 'http';



const HealthDashboard: React.FC = () => {
  const [heartrate, setHeartrate] = useState<HeartRateType>({ current_rate: 0, resting_rate: 0 })
  const [isLoading, setIsLoading] = useState(true)
  const [uid, setUid] = useState<string>("")
  const history = useHistory()
  // const [leftPage,setLeftPage] = useState()
  useStartScreenTime()
  const [setTriggered] = useEndScreenTime()
  const [, setUserId, ,] = useAuth()

  // LocalNotifications.requestPermissions()




  function logOut() {
    const auth = getAuth()

    if (isPlatform("capacitor")) {
      Echo.logout().then(() => {
        signOut(auth);
        setUserId(null);
        history.push("/login")
      }).catch(() => {
        console.error('unable to logout')
        Toast.show({ duration: 'long', text: 'unable to signout' })
      })
    } else {
      signOut(auth).then(() => {
        setUserId(null);
        history.push("/login")
      }).catch((err) => {
        console.error('unable to log out')
        console.error(err)
      })
    }




  }

  useIonViewWillEnter(async () => {
    console.log("Entering dashboard")

    PushNotifications.removeAllListeners().then(() => {
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        const notificationInfo: NotificationType = notification.notification.data
        console.log('notification: ' + JSON.stringify(notification.notification.data))
        console.log(notification.actionId)
        if (notificationInfo.from.includes("activities")) {
          history.push("/activities/create")
          var action: TrackingActionType = {
            action: "daily-activity-reminder",
            page: "notification",
            timestamp: new Date(),
            user_id: getAuth().currentUser?.uid!
          }

          pushAction(action).then(() => {

          }).catch((err) => {
            console.error("error send action")
          })
        }

      })

    }).catch((err) => {
      console.error("unable to remove push notification listeners")
    })

    // const plugin = (window as any).FirebasePlugin;
    // plugin
    // FirebaseAuthentication.
    // plugin.auth.createUserWithEmailAndPassword("nijuser@gmail.com", "nijaisms");  
    // FirebaseAuthentication.signInWithEmailAndPassword("nijuser@gmail.com","nijaisms").then(() => {
    //   console.log("Log in Succesful")
    // })

  })

  useIonViewWillLeave(() => {
    setTriggered(true)
    PushNotifications.removeAllListeners().then(() => {

    }).catch((err) => {
      console.log('unable to remove push notification listens')
      console.error(err)
    })
  })

  // const router = useIonRouter()



  useEffect(() => {

    const auth = getAuth()
    console.log(auth.currentUser?.uid)
    if (auth.currentUser?.uid) {
      console.log("checking uid")

      setUid(auth.currentUser?.uid)
      setIsLoading(false)
    }

    async function addNotificationListener() {
      // LocalNotifications.

      var channels = await LocalNotifications.listChannels()
      console.log(channels.channels)
      try {
        await LocalNotifications.addListener("localNotificationReceived", (notificaiton) => {
          // alert("Notification Pushed");
          // console.log("Notification Pushed")

        })

        await LocalNotifications.addListener("localNotificationActionPerformed", (action) => {
          console.log("Action performed")
          alert("Notifiication clicked")
          // router.push("/stress/activities")
          history.push("/stress/activities")

        })

      } catch (error) {
        console.error(error)
      }

    }

    if (isPlatform("android") && !isPlatform("mobileweb")) {
      addNotificationListener()
    }

    console.log(import.meta.env.VITE_HEALTH_APP_VERSION)


  }, [])
  return (
    <IonPage>
      <IonMenu side="start" contentId="main" type="push">
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Start Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            {/* <IonItem button onClick={() => { logOut() }}>Log Out</IonItem> */}
            <IonItem href={isPlatform("capacitor") ? "tel:3172150825 " : undefined} onClick={() => {
              if (isPlatform("capacitor")) {
                Echo.showDialer({ number: "3172150825 " })
              }

            }}>
              Call Hotline
            </IonItem>
            {/* <IonItem href='/chat' className="fab">
              Chat
            </IonItem> */}
            <IonItem href="tel:3172150825 " className="fab">
              Call Case Worker
            </IonItem>
            <IonItem href='/documentation' className="fab">
              Help Docs
            </IonItem>
            {/* <IonItem>Menu Item</IonItem>
          <IonItem>Menu Item</IonItem>
          <IonItem>Menu Item</IonItem>
          <IonItem>Menu Item</IonItem> */}
          </IonList>
        </IonContent>
        <IonFooter>
          <IonText style={{ color: 'black' }}>{import.meta.env.VITE_HEALTH_APP_VERSION}</IonText>
        </IonFooter>
      </IonMenu>
      <IonHeader>
        <PrimaryToolbar title='GOALS Health'></PrimaryToolbar>
      </IonHeader>
      <IonContent fullscreen id="main">
        {/* <IonHeader collapse="condense"> */}
        {/* <IonToolbar>
            <IonTitle size="large">Tab 1</IonTitle>
          </IonToolbar> */}
        {/* </IonHeader> */}
        {/* <ExploreContainer name="Tab 1 page" /> */}
        {/* <LineChart/> */}

        {!isLoading && <LineChart uid={uid} />}
        <IonGrid>
          <IonRow>
            <IonCol size='6'>
              <WatchConnectionCard />

            </IonCol>
            <IonCol size='6'>
              <StressManagementContainer />

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='6'>
              <HeartrateDetectionContainer heartrate={heartrate} />
            </IonCol>
          </IonRow>
        </IonGrid>
        {!isLoading && <ActivityContainer uid={uid} />}
      </IonContent>
    </IonPage>
  );
};

export default track({
  page: 'Health Dashboard Page',
},
  {
    dispatchOnMount: true,
  },)(HealthDashboard);

// export default HealthDashboard;
