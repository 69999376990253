import IStressScore from "../wrappers/IStressScore";

class StressScore {
    // question_one_points:number;
    question_one_response: string;
    question_one_notes: string;
    // question_two_points:number;
    question_two_response: string;
    question_two_notes: string;
    question_three_response: string;
    question_three_notes: string;
    question_four_response: string;
    question_four_notes: string;
    question_five_response: string;
    question_five_notes: string;
    question_six_response: string;
    question_six_notes: string;
    question_seven_response: string;
    question_seven_notes: string;
    question_eight_response: string;
    question_eight_notes: string;
    question_nine_response: string;
    question_nine_notes: string;
    question_ten_response: string;
    question_ten_notes: string;
    date_submitted: string;
    recommendations_id: number;
    // perceived_stress_score:number;
    activities: string;
    participant_id: string;

    constructor() {
        // this.question_one_points = 0
        this.question_one_response = ""
        this.question_one_notes = ""
        // this.question_two_points = 0
        this.question_two_response = ""
        this.question_two_notes = ""
        this.question_three_response = ""
        this.question_three_notes = ""
        this.question_four_response = ""
        this.question_four_notes = ""
        this.question_five_response = ""
        this.question_five_notes = ""
        this.question_six_response = ""
        this.question_six_notes = ""
        this.question_seven_response = ""
        this.question_seven_notes = ""
        this.question_eight_response = ""
        this.question_eight_notes = ""
        this.question_nine_response = ""
        this.question_nine_notes = ""
        this.question_ten_response = ""
        this.question_ten_notes = ""
        this.date_submitted = ""
        this.recommendations_id = 0
        // this.perceived_stress_score = 0
        this.activities = ""
        this.participant_id = ""
    }


    //TODO: Calculate perceived stress scores
    private isValpoints() {

    }

    // get activities() : string{
    //     return JSON.stringify(this.activities)
    // }

    // set activities(activities:{[k:string]: string}){
    //     this.activities = activities
    // }

    get question_one_points(): number {
        var score = this.responseToPoints(this.question_one_response)

        return score
    }
    get question_two_points(): number {
        var score = this.responseToPoints(this.question_two_response)

        return score
    }

    get question_three_points(): number {
        var score = this.responseToPoints(this.question_three_response)

        return score
    }

    get question_four_points(): number {
        var score = this.reverseScore(this.responseToPoints(this.question_four_response))

        return score
    }

    get question_five_points(): number {
        var score = this.reverseScore(this.responseToPoints(this.question_five_response))

        return score
    }

    get question_six_points(): number {
        var score = this.responseToPoints(this.question_six_response)

        return score
    }

    get question_seven_points(): number {
        var score = this.reverseScore(this.responseToPoints(this.question_seven_response))

        return score
    }

    get question_eight_points(): number {
        var score = this.reverseScore(this.responseToPoints(this.question_eight_response))

        return score
    }

    get question_nine_points(): number {
        var score = this.responseToPoints(this.question_nine_response)

        return score
    }

    get question_ten_points(): number {
        var score = this.responseToPoints(this.question_ten_response)

        return score
    }

    get perceived_stress_score(): number {
        return this.question_one_points + this.question_two_points + this.question_three_points + this.question_four_points
            + this.question_five_points + this.question_six_points + this.question_seven_points + this.question_eight_points
            + this.question_nine_points + this.question_ten_points
    }

    private responseToPoints(response: string): number {
        switch (response) {
            case "Never":
                return 0
            case "Almost Never":
                return 1
            case "Sometimes":
                return 2
            case "Fairly Often":
                return 3
            case "Very Often":
                return 4
            default:
                break;
        }
        return 0
    }

    private reverseScore(score: number): number {
        switch (score) {
            case 0:
                return 4
            case 1:
                return 3
            case 2:
                return 2
            case 3:
                return 1
            case 4:
                return 0
            default:
                return -1
        }
    }

    toJson(): IStressScore {
        var obj: IStressScore = {
            question_one_points: 0,
            question_one_response: "",
            question_one_notes: "",
            question_two_points: 0,
            question_two_response: "",
            question_two_notes: "",
            question_three_points: 0,
            question_three_response: "",
            question_three_notes: "",
            question_four_points: 0,
            question_four_response: "",
            question_four_notes: "",
            question_five_points: 0,
            question_five_response: "",
            question_five_notes: "",
            question_six_points: 0,
            question_six_response: "",
            question_six_notes: "",
            question_seven_points: 0,
            question_seven_response: "",
            question_seven_notes: "",
            question_eight_points: 0,
            question_eight_response: "",
            question_eight_notes: "",
            question_nine_points: 0,
            question_nine_response: "",
            question_nine_notes: "",
            question_ten_points: 0,
            question_ten_response: "",
            question_ten_notes: "",
            date_submitted: "",
            recommendations_id: 0,
            activities: "",
            perceived_stress_score: 0,
            participant_id: "",
        }
        obj.question_one_points = this.question_one_points
        obj.question_one_response = this.question_one_response
        obj.question_one_notes = this.question_one_notes
        obj.question_two_points = this.question_two_points
        obj.question_two_response = this.question_two_response
        obj.question_two_notes = this.question_one_notes
        obj.question_three_points = this.question_three_points
        obj.question_three_response = this.question_three_response
        obj.question_three_notes = this.question_three_notes
        obj.question_four_points = this.question_four_points
        obj.question_four_response = this.question_four_response
        obj.question_four_notes = this.question_four_notes
        obj.question_five_points = this.question_five_points
        obj.question_five_response = this.question_five_response
        obj.question_five_notes = this.question_five_notes
        obj.question_six_points = this.question_six_points
        obj.question_six_response = this.question_six_response
        obj.question_six_notes = this.question_six_notes
        obj.question_seven_points = this.question_seven_points
        obj.question_seven_response = this.question_seven_response
        obj.question_seven_notes = this.question_seven_notes
        obj.question_eight_points = this.question_eight_points
        obj.question_eight_response = this.question_eight_response
        obj.question_eight_notes = this.question_eight_notes
        obj.question_nine_points = this.question_nine_points
        obj.question_nine_response = this.question_nine_response
        obj.question_nine_notes = this.question_nine_notes
        obj.question_ten_points = this.question_ten_points
        obj.question_ten_response = this.question_ten_response
        obj.question_ten_notes = this.question_ten_notes
        obj.date_submitted = this.date_submitted
        obj.recommendations_id = this.recommendations_id
        obj.perceived_stress_score = this.perceived_stress_score
        obj.activities = this.activities
        obj.participant_id = this.participant_id

        return obj
    }
}

export default StressScore;