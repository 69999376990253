import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonRow, IonText } from "@ionic/react"
import { getAuth } from "firebase/auth";
import { pin, add, medical } from "ionicons/icons";
import { useEffect, useState } from "react";


function startPSSQuestionnare() {
    console.log("openning pss questions")

}


const StressManagementContainer: React.FC = () => {
    return (
        <IonCard id="stress-card" color="tertiary" routerLink="/stress/activities" href="/stress/activities">
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonIcon color="light" icon={medical} style={{ color: 'purple' }} size="large" />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonText>
                                <h2>Stress Survey</h2>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonText>
                                <h3>Click here to complete stress survey</h3>
                            </IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>


                {/* <IonButton id="stress-creation-button" fill="clear" onClick={startPSSQuestionnare} routerLink="/stress/activities" href="/stress/activities">
                    <IonIcon icon={add} slot="end" />
                </IonButton> */}

            </IonCardContent>
        </IonCard>
    )

}

export default StressManagementContainer;