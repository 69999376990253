import { IonCol, IonRadio, IonRadioGroup, IonRow } from "@ionic/react";
import StressActivityRowProps from "../wrappers/StressActivityRowProps";
import { Drivers, Storage } from '@ionic/storage';
import { useEffect, useState } from "react";


const StressActivityRow: React.FC<StressActivityRowProps> = (props) => {

    const storage = new Storage({
        name: 'activities_temp',
        driverOrder: [Drivers.LocalStorage]
    });



    const [selected, setSelected] = useState<string>('Low');
    const [prevSelected, setPrevSelected] = useState<string>("")

    useEffect(() => {
        async function setUpStorage() {
            await storage.create();
            // storage.set(props.activity, 'Low')
        }

        setUpStorage();

    })

    return (
        <><IonRadioGroup value={selected} onIonChange={e => {
            // setPrevSelected(selected)
            // storage.remove(props.activity)
            setSelected(e.detail.value)
            console.log(selected)
            storage.set(props.activity, e.detail.value)
        }
        }>
            <IonRow>

                <IonCol size="3">
                    {props.activity}
                </IonCol>

                <IonCol size="1" offset="0">
                    <IonRadio value="None"></IonRadio>
                    {/* <IonCheckbox></IonCheckbox> */}
                </IonCol>
                <IonCol size="1" offset="1">
                    <IonRadio value="Low"></IonRadio>

                    {/* <IonCheckbox></IonCheckbox> */}
                </IonCol>
                <IonCol size="1" offset="1">
                    <IonRadio value="Moderate"></IonRadio>

                    {/* <IonCheckbox></IonCheckbox> */}
                </IonCol>
                <IonCol size="1" offset="2">
                    <IonRadio value="High"></IonRadio>

                    {/* <IonCheckbox></IonCheckbox> */}
                </IonCol>

            </IonRow>
        </IonRadioGroup></>
    )

}

export default StressActivityRow;