import { IonAccordion, IonAccordionGroup, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonPage, IonThumbnail } from "@ionic/react"

import image from '../img/stress_score_example.png'
import stressScoreContainerHighlighted from '../img/stress_score_container_highlighted.png'
import PrimaryToolbar from "../components/PrimaryToolbar"
import { chevronDown } from "ionicons/icons"
const DocumentationPage: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <PrimaryToolbar title='Help Docs'></PrimaryToolbar>

            </IonHeader>
            <IonContent>
                {/* <h3>Table of Contents</h3>
                <ol>
                    <li><a>Stress</a></li>
                    <ol>
                        <li><a>Stress Score Graph</a></li>
                        <li><a>How to fill out Stress Survey</a></li>
                    </ol>
                </ol> */}
                <IonAccordionGroup>
                    <IonAccordion value="first">
                        <IonItem slot="header">
                            <IonLabel>Stress Score Graph</IonLabel>
                        </IonItem>
                        <IonCard slot="content">
                            <IonCardHeader>
                                {/* <IonCardTitle>Stress Score Graph</IonCardTitle> */}
                                <IonImg src={image} />

                            </IonCardHeader>
                            <IonCardContent>
                                <p>The picture above is a graph of what we call your Perceived Stress Score.
                                    On the bottom of the graph is the date of your stress score. On the left side
                                    of the graph are the stress scores
                                    that you have received over the last few months. </p>
                            </IonCardContent>

                        </IonCard>
                    </IonAccordion>
                    <IonAccordion value="second">
                        <IonItem slot="header">
                            <IonLabel>Stress Survey</IonLabel>
                        </IonItem>
                        <IonCard slot="content">
                            <IonCardHeader>
                                {/* <IonCardTitle>How to fill out stress survey</IonCardTitle> */}
                                <IonImg src={stressScoreContainerHighlighted} />

                            </IonCardHeader>
                            <IonCardContent>
                                <p>To fill out a stress survey, navigate to your <a href="/">Home</a> screen. You will then see a
                                    a box called "Stress Management" and you'll select the blue "+" sign as seen in the
                                    picture above</p>
                            </IonCardContent>

                        </IonCard>
                    </IonAccordion>
                </IonAccordionGroup>



            </IonContent>
        </IonPage>
    )
}

export default DocumentationPage;