
import axios from 'axios';
import { useEffect, useState } from 'react';
import useAuth from './useAuth';

const useFetch = <T>(url: string,param:string = "participantId") => {
    const [responses, setResponses] = useState<T[] | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [userId] = useAuth()


    useEffect(() => {
        console.log(userId)
        setIsLoading(true)
        
        if (userId != null) {
            axios.get(`${import.meta.env.VITE_API_URL}/${url}?${param}=${userId!}`).then((res) => {
            setIsLoading(false)
            setIsError(false)
            setResponses(res.data)
        }).catch((err) => {
            setIsLoading(false)
            setResponses(null)
            setIsError(true)
            console.error(err)
        });
        
        }

        
        return () => {};

    }, [url,userId]);

    return [responses, isLoading, isError] as const
};

export default useFetch;