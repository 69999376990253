import { Toast } from "@capacitor/toast"
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonText, useIonLoading } from "@ionic/react"
import { useEffect } from "react"
import { useHistory } from "react-router"
import ActivitiesCard from "../components/ActivitiesCard"
import useFetchGroupedActivites from "../hooks/useFetchGroupedActivites"
import GroupedActivities from "../types/GroupedActivities"
import noStressIcon from '../no-stress.svg'
import lowStressIcon from '../low-stress.svg'
import modStressIcon from '../mod-stress.svg'
import highStressIcon from '../high-stress.svg'


import './ActivitiesPage.css'
import PrimaryToolbar from "../components/PrimaryToolbar"
import useFetch from "../hooks/useFetch"
import Activity from "../types/Activity"
import { briefcase } from "ionicons/icons"
import ActivityType from "../wrappers/ActivityType"
import track from "react-tracking"





const ActivitiesPage: React.FC = () => {

    const [responses, isLoading, isError] = useFetchGroupedActivites<GroupedActivities>()
    const [responses2, isLoading2, isError2] = useFetch<ActivityType>("activities", "participantID")
    const [present, dismiss] = useIonLoading();
    const history = useHistory()
    useEffect(() => {
        if (isLoading) {
            present({ message: 'Loading activities', duration: 3000 })
        }

        if (isError) {
            dismiss()
            Toast.show({ text: 'Error loading activities', duration: 'long' })
            // history.push('/')
        }
    }, [isLoading, isError, responses])

    function convertDay(day: number): string {
        switch (day) {
            case 0:
                return "Sunday"
            case 1:
                return "Monday"
            case 2:
                return "Tuesday"
            case 3:
                return "Wednesday"
            case 4:
                return "Thursday"
            case 5:
                return "Friday"
            case 6:
                return "Saturday"


            default:
                return "unknown"
        }
    }

    function convertMonth(month: number): string {
        switch (month) {
            case 0:
                return "January"
            case 1:
                return "February"
            case 2:
                return "March"
            case 3:
                return "April"
            case 4:
                return "May"
            case 5:
                return "June"
            case 6:
                return "July"
            case 7:
                return "August"
            case 8:
                return "September"
            case 9:
                return "October"
            case 10:
                return "November"
            case 11:
                return "December"

            default:
                return "unknown"
        }
    }
    return (
        <IonPage>
            <IonHeader>
                <PrimaryToolbar title='Logged Activitites'></PrimaryToolbar>

            </IonHeader>
            <IonContent>
                <IonText><h1>Recent Activities</h1></IonText>
                {/* <IonText><h2>Today's Progress</h2></IonText> */}
                {/* <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard id="coping-card">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol><IonText><h2>Coping</h2></IonText></IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol><IonText><h2>10 logged</h2></IonText></IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol>
                            <IonCard id="health-card">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol><IonText><h2>Health</h2></IonText></IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol><IonText><h2>10 logged</h2></IonText></IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol>
                            <IonCard id="recreational-card">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol><IonText><h2>Reacreational</h2></IonText></IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol><IonText><h2>10 logged</h2></IonText></IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                        <IonCol>
                            <IonCard id="other-card">
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol><IonText><h2>Other</h2></IonText></IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol><IonText><h2>10 logged</h2></IonText></IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid> */}

                {
                    responses2 != undefined && [...responses2].reverse().map((act) => {
                        return (
                            <IonCard>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="2">
                                                <IonIcon icon={briefcase} size="large"></IonIcon>
                                            </IonCol>
                                            <IonCol size="5">
                                                <IonText><h2><b>{act.name}</b></h2></IonText>
                                                <IonText><p>{act.stressLevel} Stress</p></IonText>
                                            </IonCol>
                                            <IonCol size="5">
                                                <IonText>
                                                    <IonChip>{`${new Date(act.startTime!).getMonth() + 1}/${new Date(act.startTime!).getDate()}/${new Date(act.startTime!).getFullYear()}`}</IonChip>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        )
                    })
                }
                {/* 
                {responses?.map((groupActivity) => {
                    return (
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>{`${convertDay(new Date(groupActivity.startTime).getUTCDay())} 
                                ${new Date(groupActivity.startTime).getUTCDate()} ${convertMonth(new Date(groupActivity.startTime).getMonth())}`}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                {groupActivity.noStressActivities.length > 0 && <ActivitiesCard label="No Stress" activities={groupActivity.noStressActivities} stressIcon={noStressIcon} />}

                                {groupActivity.lowStressActivities.length > 0 && <ActivitiesCard stressIcon={lowStressIcon} label={"Low Stress"} activities={groupActivity.lowStressActivities} />}

                                {groupActivity.moderateStressActivities.length > 0 && <ActivitiesCard label="Moderate Stress" stressIcon={modStressIcon} activities={groupActivity.moderateStressActivities} />}

                                {groupActivity.highStressActivities.length > 0 && <ActivitiesCard label="High Stress" stressIcon={highStressIcon} activities={groupActivity.highStressActivities} />}

                            </IonCardContent>
                        </IonCard>
                    )
                })} */}

            </IonContent>
        </IonPage>
    )
}

export default track({
    page: 'Activities Page',
},
    {
        dispatchOnMount: true,
    },)(ActivitiesPage);
