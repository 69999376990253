import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, useIonViewWillEnter } from "@ionic/react";
import { add, body } from "ionicons/icons";
import ActivityList from "./ActivityList";
import { useState } from "react";
import ActivityType from "../wrappers/ActivityType";
import { getActivities } from "../services/StressAPIService";
import ActivityContainerProps from "../wrappers/ActivityContainerProps";

function logNewActivity() {
    console.log('creating new activity');
}



const ActivityContainer: React.FC<ActivityContainerProps> = ({ uid }) => {
    const [activites, setActivities] = useState<ActivityType[]>([])
    var newActivities: ActivityType[] = [];


    useIonViewWillEnter(async () => {
        try {
            var startTime = new Date();
            startTime.setHours(1)

            var endTime = new Date();
            endTime.setHours(23)
            newActivities = await getActivities(uid, startTime.toISOString(), endTime.toISOString())
            setActivities(newActivities)
        } catch (error) {
            setActivities([])
        }
    })

    return (
        <IonCard style={{ marginLeft: '4%', marginRight: '4%' }}>
            <IonItem lines="none">
                <IonIcon slot="start" icon={body} style={{ color: 'blue' }} />
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonLabel>Daily Activities</IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>


                <IonButton id="activity-creation-button" fill="clear" onClick={logNewActivity} routerLink="/activities/create">
                    <IonIcon icon={add} slot="end" />
                </IonButton>

                {/* <IonButton fill="outline" slot="start">View</IonButton> */}
            </IonItem>
            <ActivityList acitvites={activites} exercise_count={1} recreational_count={1} health_count={1}></ActivityList>
        </IonCard>


    )
}

export default ActivityContainer;