import { IonButton, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, isPlatform, useIonAlert, IonList, IonRadioGroup, IonRadio, IonInput, IonSearchbar, IonText, IonButtons, IonBackButton, useIonLoading } from "@ionic/react"
import { useState } from "react";
import { useHistory } from "react-router";
import Echo from "../classes/EchoPlugin";
import { createNewActivity } from "../services/StressAPIService";
import ActivityType from "../wrappers/ActivityType";
import NewActivityPageProps from "../wrappers/NewActivityPageProps";
import "./NewActivityPage.css"
import track from "react-tracking";

const NewActivityPage: React.FC<NewActivityPageProps> = ({ uid }) => {
  // const allActivities:string[] = ['Running','Jumping','Cooking']
  const allRecreationalActivities: string[] = ['Read a book', 'Work out', 'Watch a movie', 'Play a game on my phone']
  const allCopingActivities: string[] = ['Listen to music', 'Meditation', 'Go for a walk', 'Take deep breaths']
  const allPersonalActivities: string[] = ['Met with family']
  const allEducationalActivities: string[] = ['Attended a night class ']
  const allHealthActivities: string[] = ['Went to doctor', 'Followed Stress Recommendations', 'Went for a run', 'Went to the gym']
  const allCommuteActivities: string[] = ['Took bus to work', 'Took cab to work',]
  const allWorkActivities: string[] = ['Looked for a job', 'Email perspective employer', 'Read up on jobs I was interested in', 'Fill Out Job Application', 'Attended Job Interview']
  const [activityType, setActivityType] = useState<string>("work");
  const [activityName, setActivityName] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toLocaleString());
  const [duration, setDuration] = useState<string>("5");
  const [durationType, setDurationType] = useState<string>("minutes")
  const [availableActivities, setAvailableActivites] = useState<string[]>(allWorkActivities)
  const [present] = useIonAlert();
  const history = useHistory()
  const [stressLevel, setStressLevel] = useState<string>("empty");
  const [presentLoading, dismissLoading] = useIonLoading()

  /**
   * curl --location --request POST '192.168.0.168:8080/activities' \
      --header 'Content-Type: application/json' \
      --data-raw '{
          "name":"",
          "type":"",
          "participantID":"",
          "startTime":"",
          "duration":""
      }'
   */


  function convertDuration(duration: number, unit: string): number {
    if (unit === "minutes") {
      return duration;
    } else {
      return 60 / duration
    }
  }

  async function createActivity(name: string, type: string, participantId: string, startTime: string, duration: number, stressLevel: string) {
    // var currentDate = new Date();
    // var newDate = `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDay()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}.${currentDate.getMilliseconds()}`
    // console.log(newDate);
    // console.log(currentDate.toISOString())


    var activity: ActivityType = {
      name: name,
      startTime: startTime,
      type: type,
      duration: duration,
      stressLevel: stressLevel,
    }


    presentLoading({ message: 'Saving activity...', duration: 3000 })
    try {
      await createNewActivity(JSON.stringify(activity), uid);
      dismissLoading()
      present('Activity Saved!', [{ text: 'Ok', handler: () => history.push("/") }])
    } catch (error) {
      present('Error saving activity! Try again later.', [{ text: 'Ok', handler: () => history.push("/") }])
      console.error(error)
    }


  }



  function searchForActivity(activityName: string, activityType: string) {
    var allActivities: string[] = []
    switch (activityType) {
      case 'coping':
        allActivities = allCopingActivities
        break;
      case 'commute':
        break;
      case 'recreational':
        allActivities = allRecreationalActivities
        break;
      case 'educational':
        allActivities = allEducationalActivities
        break;
      case 'health':
        allActivities = allHealthActivities
        break;
      case 'personal':
        allActivities = allPersonalActivities
        break;
      case 'work':
        allActivities = allWorkActivities
        break;
      default:
        break;
    }

    var filteredActivies: string[] = []
    for (let index = 0; index < allActivities.length; index++) {
      const activity = allActivities[index];

      if (activity.startsWith(activityName)) {
        filteredActivies.push(activity)
      }
    }

    if (activityName !== "") {
      filteredActivies.push(activityName)
    }



    setAvailableActivites(filteredActivies)
  }

  function updateActivities(activityType: string) {
    setActivityType(activityType)
    switch (activityType) {
      case 'coping':
        setAvailableActivites(allCopingActivities)
        break;
      case 'commute':
        setAvailableActivites(allCommuteActivities)
        break;
      case 'recreational':
        setAvailableActivites(allRecreationalActivities)
        break;
      case 'health':
        setAvailableActivites(allHealthActivities)
        break
      case 'work':
        setAvailableActivites(allWorkActivities)
        break;
      default:
        break;
    }
  }

  return (
    <IonPage>
      <IonHeader collapse="condense">
        <IonToolbar>

          <IonButtons slot="start">

            <IonBackButton />
          </IonButtons>
          <IonTitle>Add new activity</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent >


        <IonList className="input-box">
          <IonRadioGroup value={activityType} onIonChange={e => updateActivities(e.detail.value)}>

            <IonLabel className="bold-label">Activity Type</IonLabel>
            <IonItem>
              <IonLabel>Work</IonLabel>
              <IonRadio slot="start" value="work" />
              {/* <IonButton id="trigger-button">
                  <IonIcon icon={helpOutline} slot="end" />
                </IonButton> */}

              {/* <IonPopover isOpen={false} trigger="trigger-button">
                  <IonContent>My Content</IonContent>
                </IonPopover> */}
              {/* <IonNote>Option description here</IonNote> */}
            </IonItem>

            <IonItem>
              <IonLabel>Coping</IonLabel>
              <IonRadio slot="start" value="coping" />
            </IonItem>

            {/* <IonItem>
              <IonLabel>Commute</IonLabel>
              <IonRadio slot="start" value="commmute" />
            </IonItem> */}
            <IonItem>
              <IonLabel>Recreational</IonLabel>
              <IonRadio slot="start" value="recreational" />
            </IonItem>
            <IonItem>
              <IonLabel>Health</IonLabel>
              <IonRadio slot="start" value="health" />
            </IonItem>
          </IonRadioGroup>
          {/* <IonItemDivider>Your Selection</IonItemDivider>
          <IonItem>{activityType ?? '(none selected'}</IonItem> */}
        </IonList>

        <IonLabel className="bold-label input-box" position="stacked">Activity Name</IonLabel>
        <IonSearchbar placeholder="Type actvity or create new one" onIonChange={(e) => searchForActivity(e.detail.value!, activityType)}></IonSearchbar>


        <IonList className="input-box">
          <IonRadioGroup onIonChange={e => setActivityName(e.detail.value)}>
            {availableActivities.map((activity, index) => {
              return (
                <IonItem key={index} >
                  <IonLabel>{activity}</IonLabel>
                  <IonRadio value={activity}></IonRadio>
                </IonItem>
              )
            })}
          </IonRadioGroup>

        </IonList>

        {/* <IonItem className="input-box"> */}
        {/* <IonLabel>Activity Type</IonLabel> */}
        {/* <IonSelect value={activityType} okText="Okay" cancelText="Dismiss" onIonChange={e => setActivityType(e.detail.value)}>
              <IonSelectOption value="work">Work</IonSelectOption>
              <IonSelectOption value="health">Health</IonSelectOption>
              <IonSelectOption value="commute">Commute</IonSelectOption>
              <IonSelectOption value="recreational">Recreational</IonSelectOption>
            </IonSelect> */}

        {/* </IonItem> */}
        {/* <IonItem className="input-box">
            <IonLabel>Activity Name</IonLabel>
            {activityType === "work" &&
                 <IonSelect value={activityName} okText="Okay" cancelText="Dismiss" onIonChange={e => setActivityName(e.detail.value)}>
              <IonSelectOption value="work 1">Work 1</IonSelectOption>
              <IonSelectOption value="work 2">Work 2</IonSelectOption>
              <IonSelectOption value="work 3">Work 3</IonSelectOption>
              <IonSelectOption value="work 4">Work 4</IonSelectOption>
            </IonSelect>
            }

                        {activityType === "health" &&
                 <IonSelect value={activityName} okText="Okay" cancelText="Dismiss" onIonChange={e => setActivityName(e.detail.value)}>
              <IonSelectOption value="heatlh 1">Health 1</IonSelectOption>
              <IonSelectOption value="health 2">Health 2</IonSelectOption>
              <IonSelectOption value="health 3">Health 3</IonSelectOption>
              <IonSelectOption value="health 4">Health 4</IonSelectOption>
            </IonSelect>
            }
         
          </IonItem> */}


        <IonItem className="input-box" onClick={() => {
          if (isPlatform("android") && !isPlatform("mobileweb")) {
            Echo.showNativeCalendar().then((res) => {
              setSelectedDate(new Date(res.timestamp).toLocaleString())
            }).catch((err) => {
              console.error(err)
            })
          }
        }}>
          <IonLabel position="stacked">Start Time</IonLabel>
          <IonText>{selectedDate}</IonText>
          {/* <IonDatetime pickerFormat="YYYY-MM-DDTHH:mm" pickerOptions={{
            buttons: [
              {
                text: 'Save',
                handler: () => console.log('Clicked Save!' + selectedDate)
              }, {
                text: 'Log',
                handler: () => {
                  console.log('Clicked Log. Do not Dismiss.');
                  return false;
                }
              }
            ]
          }}
            placeholder="Custom Options" displayFormat="YYYY" min="2021" max="2022"
            value={selectedDate} onIonChange={e => setSelectedDate(e.detail.value!)}>
          </IonDatetime> */}
        </IonItem>
        <IonItem className="input-box">
          <IonLabel position="stacked">Duration</IonLabel>
          {/* <IonSelect value={duration} okText="Okay" cancelText="Dismiss" onIonChange={e => setDuration(e.detail.value)}>
              <IonSelectOption value="5">5 minutes</IonSelectOption>
              <IonSelectOption value="15">15 minutes</IonSelectOption>
              <IonSelectOption value="30">30 mintues</IonSelectOption>
              <IonSelectOption value="60">60 minutes</IonSelectOption>
            </IonSelect> */}
          <IonInput type="number" value={duration} onIonChange={(e) => setDuration(e.detail.value!)}></IonInput>
          {/* <IonInput type="text" value={durationType} onIonChange={(e) => setDurationType(e.detail.value!)}></IonInput> */}
          <IonSelect value={durationType} onIonChange={(e) => setDurationType(e.detail.value)}>
            <IonSelectOption value="minutes">minutes</IonSelectOption>
            <IonSelectOption value="hours">hours</IonSelectOption>
          </IonSelect>

        </IonItem>
        <IonItem className="input-box">
          <IonLabel position="stacked">Stress Level</IonLabel>
          <IonSelect value={stressLevel} placeholder="StressLevel" onIonChange={(e) => setStressLevel(e.detail.value)}>
            <IonSelectOption value="empty">-</IonSelectOption>
            <IonSelectOption value="none">None</IonSelectOption>
            <IonSelectOption value="low">Low</IonSelectOption>
            <IonSelectOption value="moderate">Moderate</IonSelectOption>
            <IonSelectOption value="high">High</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonButton onClick={() => {
          if (stressLevel === "empty") {
            present('Stress Level Required!', [{ text: 'Ok' }])

          } else {
            createActivity(activityName, activityType, "11111112", new Date(selectedDate).toISOString(), convertDuration(parseInt(duration), durationType), stressLevel)

          }
        }}>Log Activity</IonButton>
      </IonContent>
    </IonPage>
  )
}

export default track({
  page: 'New Activity Page',
},
  {
    dispatchOnMount: true,
  },)(NewActivityPage);
