import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { App as MyApp } from '@capacitor/app'
import TrackingActionType from './types/TrackingActionType';
import { Storage } from '@ionic/storage';
import { pushAction, pushTracking } from './api/api';
import TrackingPageType from './types/TrackingPageType';
import track from "react-tracking";
import { initializeApp, } from 'firebase/app';
import { getAuth } from 'firebase/auth';

if (import.meta.env.VITE_ENVIRONMENT === 'development') {
  const firebaseConfig = {

    apiKey: "AIzaSyCDFh6ef8LkrktUxl4bSm_ViRMg9JVhrzs",

    authDomain: "nij-authentication-dev.firebaseapp.com",

    projectId: "nij-authentication-dev",

    storageBucket: "nij-authentication-dev.appspot.com",

    messagingSenderId: "374498573781",

    appId: "1:374498573781:web:dc514e5d62ec35a997515c",

    measurementId: "G-213K2C29HH"

  };



  const app = initializeApp(firebaseConfig);
} else {
  const firebaseConfig = {
    apiKey: "AIzaSyC1A5cSaGy3_pfj9Mpd2Vw8eBmRl9d3ws4",
    authDomain: "nij-authentication.firebaseapp.com",
    projectId: "nij-authentication",
    storageBucket: "nij-authentication.appspot.com",
    messagingSenderId: "17504360767",
    appId: "1:17504360767:web:663eceabb9dd7724b5db8a",
    measurementId: "G-FFXJGTTMFS"
  };



  const app = initializeApp(firebaseConfig);
}


axiosRetry(axios, { retries: 3 });

var prevPageTime = new Date()
var prevPageName = ""
var store = new Storage({ name: '__tracking' })

MyApp.addListener('appStateChange', async ({ isActive }) => {

  if (!isActive) {
    await store.create()
    prevPageTime = await store.get("time") === undefined ? new Date() : new Date(await store.get("time"))
    prevPageName = await store.get("page") === undefined ? "startup" : await store.get("page")

    const currentPageDate = new Date()



    console.log(prevPageTime.toISOString())
    console.log(currentPageDate.toISOString())



    console.log((currentPageDate.getTime() - prevPageTime.getTime()) / 1000)
    console.log("from " + prevPageName + " to " + "closed")
    // data.time = Math.floor((currentPageDate.getTime() - prevPageTime.getTime()) / 1000)
    var time = Math.floor((currentPageDate.getTime() - prevPageTime.getTime()) / 1000)
    await store.set("time", currentPageDate.toISOString())
    await store.set("page", "closed")

    var event: TrackingPageType = {
      page: prevPageName,
      time: time,
      timestamp: currentPageDate,
      user_id: getAuth().currentUser?.uid!
    }
    try {
      await pushTracking(event)
    } catch (error) {
      console.error(error)
    }
  }

})



const TrackedApp = track({
  app: 'nij-health'
}, {
  dispatch: async data => {

    if (data.action === undefined) {
      console.log('on page visit')
      await store.create()
      // console.log(await store.get("time"))
      prevPageTime = await store.get("time") === undefined ? new Date() : new Date(await store.get("time"))
      prevPageName = await store.get("page") === undefined ? "startup" : await store.get("page")
      const currentPageDate = new Date()



      console.log(prevPageTime.toISOString())
      console.log(currentPageDate.toISOString())



      console.log((currentPageDate.getTime() - prevPageTime.getTime()) / 1000)
      console.log("from " + prevPageName + " to " + data.page)
      data.time = Math.floor((currentPageDate.getTime() - prevPageTime.getTime()) / 1000)
      await store.set("time", currentPageDate.toISOString())
      await store.set("page", data.page)
      // if(prevPageTime === "")
      //Appending timestamp to each dispatch to track when events were submitted
      data.timestamp = new Date()

      data.user = getAuth().currentUser?.uid

      //If action isn't defined, then event was triggered when page was mounted

      var event: TrackingPageType = {
        page: prevPageName,
        time: data.time,
        timestamp: data.timestamp,
        user_id: getAuth().currentUser?.uid!
      }
      try {
        await pushTracking(event)
      } catch (error) {
        console.error(error)
      }


    } else {

      var action: TrackingActionType = {
        action: data.action,
        page: data.page,
        timestamp: new Date(),
        user_id: getAuth().currentUser?.uid!

      }

      try {
        await pushAction(action)
      } catch (error) {
        console.error(error)
      }

    }

    console.log(data);



  }
})(App)

ReactDOM.render(
  <React.StrictMode>
    <TrackedApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
