import { IonCard, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getLatestStressScores } from '../services/StressAPIService';
import StressScoreChartProps from '../wrappers/StressScoreChartProps';
import * as Sentry from "@sentry/browser";


// const data = {
//   labels: ['1 ', '2', '3', '4', '5', '6'],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       fill: false,
//       backgroundColor: 'rgb(255, 99, 132)',
//       borderColor: 'rgba(255, 99, 132, 0.2)',
//     },
//   ],
// };

// const options = {
//   scales: {
//     yAxes: [
//       {
//         ticks: {
//           beginAtZero: true,
//         },
//       },
//     ],
//   },
// };

// type DeepPartial<

// const scaleOptions:ScaleOptionsByType = {
//     type:"linear",

// }

// const typedOptions:ChartOptions = {
//     scales:scaleOptions,
// };

function generateLabels() {

}



const LineChart: React.FC<StressScoreChartProps> = ({ uid }) => {

  const [scores, setScores] = useState<number[]>([])
  const [labels, setLabels] = useState<string[]>([])
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Perceived Stress Scores',
        data: scores,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };



  useIonViewWillEnter(() => {
    async function getScores() {
      try {
        var history = (await getLatestStressScores(uid)).data;
        var scores: number[] = []
        var dates: string[] = []
        for (let index = 0; index < history.length; index++) {
          const item: any = history[index];
          scores.push(item[0])
          dates.push(item[1])
        }

        var labels = []

        for (let index = 0; index < dates.length; index++) {
          const date = dates[index];
          labels.push(`${new Date(date).getMonth() + 1}/${new Date(date).getDate()}`)
        }
        setLabels(labels)
        setScores(scores)
      } catch (error: any) {
        console.error(error)
        Sentry.setExtra('error', error)
        Sentry.captureException(error);
        // setLabels([])
        // setScores([])
      }


    }

    getScores()
  })

  return (
    <IonCard style={{ padding: '3%', marginLeft: '4%', marginRight: '4%' }}>
      <div className='header'>

      </div>
      <div>
        <Line data={data} />
      </div>
    </IonCard>
  )
}
export default LineChart;