import { getAuth } from "@firebase/auth";
import { IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import { heart } from "ionicons/icons";
import { useEffect, useState } from "react";
import { getCurrentHeartRate } from "../services/StressAPIService";
import HeartRateProps from "../wrappers/HeartRateProps";

const HeartrateDetectionContainer: React.FC<HeartRateProps> = (props): JSX.Element => {

    interface HeartRate {
        participant_id: string
        bpm: number
        timestamp: Date
    }

    const [heartrate, setHeartrate] = useState<HeartRate | null>(null)
    const [uid, setUid] = useState<string>("")
    async function updateHeartrate() {
        try {
            if (uid !== "") {
                var currentHeartrate = await getCurrentHeartRate(uid)
                setHeartrate(currentHeartrate)
            }



        } catch (error) {
            console.log(error)
            // setHeartrate(heartrate)
        }
    }

    useEffect(() => {
        setInterval(updateHeartrate, 15000)
        const auth = getAuth()
        console.log(auth.currentUser?.uid)
        if (auth.currentUser?.uid) {
            console.log("checking uid")

            setUid(auth.currentUser?.uid)
        }

        updateHeartrate()



    }, [uid])

    return (
        <IonCard id="heart-card" >
            <IonCardContent>

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonIcon icon={heart} color='light' size='large' />
                        </IonCol>

                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonText color="light"><h2>Heartbeat</h2></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonText color="light"><h2>{heartrate == null ? 0 : heartrate.bpm} bpm</h2></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonText color="light">Last Updated: {heartrate == null ? "Unknown" : new Date(heartrate.timestamp).toLocaleString()}</IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>

        </IonCard>
    )
}

export default HeartrateDetectionContainer;