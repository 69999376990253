import { IonAvatar, IonCard, IonContent, IonItem, IonItemDivider, IonLabel, IonList, IonPage, IonText } from "@ionic/react"

import background from '../img/cool-background.png'
import "./AccountPage.css"

const AccountPage: React.FC = () => {
    return (
        <IonPage>
            <IonContent >

                <IonItem lines="none" className="account-badge">
                    <IonLabel style={{ marginTop: '15%', marginLeft: '40%' }}>
                        <IonAvatar>
                            <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
                        </IonAvatar>
                        <IonText>John Doe</IonText>
                    </IonLabel>

                </IonItem>

                <IonList>
                    <IonItem button lines="none">
                        <IonLabel>My Info</IonLabel>
                    </IonItem>
                    <IonItemDivider></IonItemDivider>
                    <IonItem button lines="none" routerLink="/opt-out">
                        <IonText>Opt-Out</IonText>
                    </IonItem>
                    <IonItemDivider></IonItemDivider>
                </IonList>

            </IonContent>
        </IonPage>
    )
}

export default AccountPage;