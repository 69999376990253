/* eslint-disable react/react-in-jsx-scope */
import { getAuth } from '@firebase/auth';
import { IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonPage, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { browserLocalPersistence, ConfirmationResult, RecaptchaVerifier, setPersistence, signInWithEmailAndPassword, signInWithPhoneNumber } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { Toast } from '@capacitor/toast';
import Echo from '../classes/EchoPlugin';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { AuthContextType } from '../@types/auth';
import { AuthContext } from '../context/authContext';
import track from 'react-tracking';


const LoginPage: React.FC = () => {

  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const [confirmResult, setConfirmResult] = useState<ConfirmationResult>()
  const [showCode, setShowCode] = useState<boolean>(false)
  const [code, setCode] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false)
  const [hideCaptcha, setHideCaptcha] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const history = useHistory()
  const { token, user } = useContext(AuthContext) as AuthContextType


  useEffect(() => {
    if (user !== null) {
      history.push("/home")
    }
  })

  // var confirmResult: ConfirmationResult

  async function showLoginFailureTest() {
    await Toast.show({
      text: 'Login failed!',
      duration: 'long'
    });
  }
  async function verifyCode() {

    console.log('verifying code')
    if (confirmResult !== undefined) {
      console.log('verifying code')

      confirmResult.confirm(code).then((result) => {
        console.log(result.user.email)
      }).catch((err) => {
        console.log('error verifying code')

      })
    }
  }

  async function phoneNumberSignIn() {
    // import { getAuth, RecaptchaVerifier } from "firebase/auth";
    console.log('check for result')
    const auth = getAuth();

    setPersistence(auth, browserLocalPersistence).then(() => {
      let verify = new RecaptchaVerifier("recaptcha-container", {}, auth)
      signInWithPhoneNumber(auth, `+1${phoneNumber}`, verify).then((result) => {
        console.log('send sms text')
        setConfirmResult(result)
        setShowCode(true)
        setHideCaptcha(true)
      }).catch((err) => {
        console.error(err)
        showLoginFailureTest()

      })
    })

  }

  function signInWithEmail() {
    const auth = getAuth();

    setPersistence(auth, browserLocalPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, pass)
        .then(async (userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log('signed in')
          const token = await user.getIdToken();
          const id = await user.uid;
          // console.log(token)

          if (isPlatform("capacitor") || isPlatform("android")) {
            try {
              await Echo.login({ email: email, password: pass })
              const { value } = await Echo.echo({ value: token, user: id });
              console.log('Response from native:', value);
            } catch (error) {
              console.error(error);
            }
          }

          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode)
          console.error(errorMessage)
          showLoginFailureTest()
        });
    }).then(() => {
      history.push("/home")
    })



  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem>
          <IonInput className='username' type="email" placeholder="email" onIonChange={e => setEmail(e.detail.value!)}></IonInput>
        </IonItem>
        <IonItem>
          <IonInput className='password' type={showPassword ? 'text' : 'password'} placeholder="password" onIonChange={e => setPass(e.detail.value!)}></IonInput>
          <IonIcon onClick={() => setShowPassword(!showPassword)} src={showPassword ? eyeOutline : eyeOffOutline}></IonIcon>

        </IonItem>
        <IonItem>
          <IonButton className='login-button' onClick={signInWithEmail}>Login</IonButton>
        </IonItem>
        {/* <IonItem>
          <IonButton className='phone-number-sign-in' onClick={() => setShowPhoneNumber(true)}>Phone Number Sign In</IonButton>
        </IonItem> */}
        <IonItem hidden={!showPhoneNumber}>
          <IonInput className='phone-number-input' placeholder='Enter phone number' onIonChange={e => setPhoneNumber(e.detail.value!)}></IonInput>
        </IonItem>
        <IonItem hidden={!showPhoneNumber} onClick={phoneNumberSignIn}>
          <IonButton className='send-code-button'>Send Text</IonButton>
        </IonItem>
        <IonItem hidden={!showCode}>
          <IonInput className='verification-input' placeholder='Enter Code' onIonChange={e => setCode(e.detail.value!)}></IonInput>
        </IonItem>
        <IonItem hidden={!showCode}>
          <IonButton className='verification-button' onClick={verifyCode}>Verify</IonButton>
        </IonItem>
        <div id="recaptcha-container" hidden={hideCaptcha}>

        </div>

      </IonContent>
    </IonPage>
  );
};

export default track({
  page: 'Login Page',
},
  {
    dispatchOnMount: true,
  },)(LoginPage);

