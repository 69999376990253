import axios from 'axios';
import { useEffect, useState } from 'react';
import { Storage } from '@ionic/storage';
import { useHistory } from 'react-router';

const useStartScreenTime = () => {
    // const [responses, setResponses] = useState<T[] | null>();
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    //     const [isError, setIsError] = useState<boolean>(false);
    const history = useHistory()
    const store = new Storage({
        name: '__screen_time'
    });
    async function createStorage() {

        await store.create();
    }

    useEffect(() => {

        setTimeout(() => {
            createStorage().then(() => {
                let startTime = new Date().toISOString();
                store.set(history.location.pathname, startTime).then((val) => {
                    console.log(val)
                    console.log(history.location.pathname)
                })

            })
        }, 10000)

    });
};

export default useStartScreenTime;