import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonRow, IonText, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
// import ExploreContainer from '../components/ExploreContainer';
// import './Tab2.css';
import { Drivers, Storage } from '@ionic/storage';
import axios from 'axios';
import { useState } from 'react';
import StressActivityRow from './StressActivityRow';

interface ActivityStressPageProps {
  uid: string
}

const ActivityStressPage: React.FC<ActivityStressPageProps> = ({ uid }) => {

  const storage = new Storage({
    name: 'activities_temp',
    driverOrder: [Drivers.LocalStorage]
  });

  const [activities, setActivities] = useState<string[]>([])

  useIonViewWillEnter(async () => {
    //TODO: Query unique activities

    await storage.create();
    var startTime = new Date();
    startTime.setDate(startTime.getDate() - 14)
    var endTime = new Date();
    try {
      const uniqueActivities = await (await axios.get(`${import.meta.env.VITE_API_URL}/activities/unique?participantId=${uid}&startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}`, {
        headers: {
          'Content-Type': 'application/json'
        },
      })).data
      console.log(uniqueActivities)
      setActivities(uniqueActivities)
      uniqueActivities.forEach((activity: string) => {
        console.log(activities)
        storage.set(activity, 'Low')
      });

    } catch (err) {
      console.error(err)
    }




  })
  return (
    <IonPage>
      <IonHeader collapse="condense">
        <IonToolbar>

          <IonButtons slot="start">

            <IonBackButton />
          </IonButtons>
          <IonTitle size="small">Select Stress Levels for Past Activities</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonToolbar>

          <IonButtons slot="start">

            <IonBackButton />
          </IonButtons>
          <IonTitle >Select Stress Levels for Logged Activities</IonTitle>
        </IonToolbar>

        <IonItem lines='none'>
          Below are the activties that you have entered over the last 2 weeks. You can update the stress level of an
          activity or you can hit next and move on to the next set of questions.
        </IonItem>

        <IonGrid>
          <IonRow>
            <IonCol size="3">Activity</IonCol>
            <IonCol size="2">None</IonCol>
            <IonCol size="2">Low</IonCol>
            <IonCol size="3">Moderate</IonCol>
            <IonCol size="2">High</IonCol>
          </IonRow>

          {activities.map((activity, index) => {
            return (
              <StressActivityRow key={index} activity={activity} />
            )

          })}
        </IonGrid>
        <IonItem routerLink="/stress/survey">
          <IonButton size="default" >Next</IonButton>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default ActivityStressPage;
