import axios from "axios"
import { getAuth } from "firebase/auth"
import { useEffect, useState } from "react"

const useAuth = () => {

    const [userId, setUserId] = useState<string | null>(null)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [token,setToken] = useState<string>("")
    useEffect(() => {
        const auth = getAuth()

        auth.onAuthStateChanged(async (user) => {

            if (user != null) {
                // console.log(user.getIdToken())
                axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
                setUserId(user.uid)
                setToken(await user.getIdToken())
            }

        })

        auth.onIdTokenChanged(async (user) => {
            if (user != null) {
                // console.log(await user.getIdToken())
                axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
                setUserId(user.uid)
                setToken(await user.getIdToken())
            }

        })

        // do {
        //     setInterval(() => {console.log('verifying auth')},5000)
        // } while (userId === null);
        console.log(auth.currentUser)
        if (auth.currentUser != null) {
            setLoggedIn(true)
            setUserId(auth.currentUser.uid)
        }


    }, [userId, loggedIn, setUserId,token])

    return [userId, setUserId, loggedIn,token] as const;
}

export default useAuth;