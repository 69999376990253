import { useContext, useEffect } from "react"
import { Redirect } from "react-router"
import { AuthContextType } from "../@types/auth"
import { AuthContext } from "../context/authContext"
import HealthDashboard from "../pages/HealthDashboard"
import HeartratePage from "../pages/HeartratePage"
import LoginPage from "../pages/LoginPage"

const ProtectedRoute: React.FC<{ title: string }> = () => {
    const { token, user } = useContext(AuthContext) as AuthContextType
    // console.log(user)

    useEffect(() => {

    }, [user])

    return (
        <>
            {user === null ? <Redirect to="/login" /> : <HealthDashboard />}

        </>
    )
}

export default ProtectedRoute;