
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Storage } from '@ionic/storage';
import { useHistory } from 'react-router';
import { useIonAlert } from '@ionic/react';
import { Toast } from '@capacitor/toast';

const useNotice = () => {
    // const [responses, setResponses] = useState<T[] | null>();
    // const [isLoading, setIsLoading] = useState<boolean>(false);
    //     const [isError, setIsError] = useState<boolean>(false);
    const [present] = useIonAlert();
    const history = useHistory()
    const store = new Storage({
        name: '__start_up_notice'
    });
    async function createStorage() {

        await store.create();
        if (await store.length() === 0) {
            await store.set("USER_APPROVED", false);
        }

    }

    async function checkApproval() {

        if (await store.get("USER_APPROVED") === false) {
            present({
                header: 'App Notice', message: 'By selecting "OK" below, you understand that the stress data being collected is only used for research purposes.', buttons: ['Close', {
                    text: 'Continue', handler: (async (d) => {
                        await store.set("USER_APPROVED", true)
                    })
                }]
            })
        }
    }

    useEffect(() => {

        createStorage().then(() => {
            checkApproval()
        }).catch((err) => {
            console.error(err)
            Toast.show({ text: 'Error showing notice', duration: 'long' })
        })

    }, []);
};

export default useNotice;