import React, { useState } from 'react';
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
} from '@ionic/react';
import './ChatPage.css';

const ChatPage = () => {
    const [messages, setMessages] = useState<any>([]);
    const [newMessage, setNewMessage] = useState<any>('');

    const staticMessages = [
        { id: 1, text: 'Hello', timestamp: '2023-06-01 10:00:00' },
        { id: 2, text: 'How are you?', timestamp: '2023-06-01 10:05:00' },
        { id: 3, text: 'I am good. Thanks!', timestamp: '2023-06-01 10:10:00' },
    ];

    const handleSendMessage = () => {
        const timestamp = new Date().toLocaleString();

        const newMessageObj = {
            id: messages.length + 1,
            text: newMessage,
            timestamp: timestamp,
        };

        setMessages((prevMessages: any) => [...prevMessages, newMessageObj]);
        setNewMessage('');
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Chat</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList lines="none">
                    {messages.map((message: any) => (
                        <IonItem key={message.id} className={`message ${message.id % 2 === 0 ? 'outgoing' : 'incoming'}`} >
                            <IonLabel>
                                <h2>{message.text}</h2>
                                <p>{message.timestamp}</p>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                <div className="input-area">
                    <IonInput
                        placeholder="Type a message..."
                        value={newMessage}
                        onIonChange={(e) => setNewMessage(e.target.value)}
                    ></IonInput>
                    <IonButton expand="full" onClick={handleSendMessage}>
                        Send
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ChatPage;