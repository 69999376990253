import { IonButton, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRadio, IonRadioGroup, IonTextarea, IonTitle, IonToolbar, useIonLoading, useIonViewWillEnter } from "@ionic/react"
import { useState } from "react";
import StressScore from "../classes/StressScore";
import { Drivers, Storage } from '@ionic/storage';
import { createStressScore } from "../services/StressAPIService"
import { useHistory } from "react-router";
import StressQuestionPageProps from "../wrappers/StressQuestionPageProps";
import { Toast } from "@capacitor/toast"


const StressQuestionPage: React.FC<StressQuestionPageProps> = ({ uid }) => {

  const storage = new Storage({
    name: 'activities_temp',
    driverOrder: [Drivers.LocalStorage]
  });

  const [selected, setSelected] = useState("")
  const [notes, setNotes] = useState("")
  const [counter, setCounter] = useState(1)
  const [response, setResponse] = useState("")
  const [responseMap, setResponseMap] = useState<{ [k: string]: string }>({})
  const history = useHistory()
  const [present, dismiss] = useIonLoading()

  useIonViewWillEnter(() => {

    // setUpStorage()
  })
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Question {counter}/10</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonRadioGroup value={response} onIonChange={e => setResponse(e.detail.value)}>
            <IonListHeader>
              <IonLabel>
                {counter === 1 &&
                  <div>In the last week, how often have you been upset
                    because of somethings that happened recently</div>
                }

                {counter === 2 &&
                  <div>In the last month, how often have you felt that you were unable
                    to control the important things in your life?
                  </div>
                }

                {counter === 3 &&
                  <div>In the last month, how often have you felt nervous or stressed?</div>
                }

                {counter === 4 &&
                  <div>In the last month, how often have you felt confident about your ability
                    to handle your personal problems?
                  </div>
                }

                {counter === 5 &&
                  <div>In the last month, how often have you felt that things were going your way?</div>
                }

                {counter === 6 &&
                  <div>In the last month, how often have you found that you could not cope
                    with all the things you had to do?
                  </div>
                }

                {counter === 7 &&
                  <div>In the last month, how often have you been able to controll irritations
                    in your life?
                  </div>
                }

                {counter === 8 &&
                  <div>In the last month, how often have you felt that you were
                    on top of things?
                  </div>
                }

                {counter === 9 &&
                  <div>In the last month, how often have you been angered because of
                    things that happened that were outside of your control?
                  </div>
                }

                {counter === 10 &&
                  <div>In the last month, how often have you felt difficulties were piling
                    up so high that you could not overcome them?
                  </div>
                }

              </IonLabel>

            </IonListHeader>

            <IonItem>
              <IonLabel>Never (0 times per week)</IonLabel>
              <IonRadio slot="start" value="Never" />
            </IonItem>

            <IonItem>
              <IonLabel>Almost Never (1-2 times per week)</IonLabel>
              <IonRadio slot="start" value="Almost Never" />
            </IonItem>

            <IonItem>
              <IonLabel>Sometimes (3 - 5 times per week)</IonLabel>
              <IonRadio slot="start" value="Sometimes" />
            </IonItem>
            <IonItem>
              <IonLabel>Fairly Often (6 - 10 times per week)</IonLabel>
              <IonRadio slot="start" value="Fairly Often" />
            </IonItem>
            <IonItem>
              <IonLabel>Very Often (11 or more times per week)</IonLabel>
              <IonRadio slot="start" value="Very Often" />
            </IonItem>
          </IonRadioGroup>
        </IonList>
        <IonItem>
          <IonLabel position="floating">Other Notes</IonLabel>
          <IonTextarea value={notes} onIonChange={e => setNotes(e.detail.value!)}></IonTextarea>
        </IonItem>
        <IonItem>
          {/* <IonButton size="default" onClick={() => {
            let map = responseMap
            map[`${counter}`] = response
            map[`${counter}_notes`] = notes
            setResponseMap(map)
            setCounter(counter - 1)
          }}>Previous</IonButton> */}

          {counter === 10 &&
            <IonButton size="default" onClick={async () => {
              let map = responseMap
              map[counter] = response
              setResponseMap(map)

              await storage.create();

              const activities = await storage.keys()
              console.log(activities)
              var activityMap: { [k: string]: string } = {}
              activities.forEach(async key => {
                console.log(key)
                activityMap[key] = await storage.get(key)
                // activityMap = activityMap + `${key}:${await storage.get(key)};`
              });

              console.log(activityMap)

              for (let index = 0; index < activities.length; index++) {
                activityMap[activities[index]] = await storage.get(activities[index])

              }

              console.log(activityMap)

              //TODO: Store actual points for score

              var currentDate = new Date();
              var score = new StressScore();
              score.date_submitted = currentDate.toISOString()
              // score.question_one_points = 0
              score.question_one_response = responseMap["1"]
              score.question_one_notes = responseMap["1_notes"]
              // score.question_two_points = 0
              score.question_two_response = responseMap["2"]
              score.question_two_notes = responseMap["2_notes"]
              score.question_three_response = responseMap["3"]
              score.question_three_notes = responseMap["3_notes"]
              score.question_four_response = responseMap["4"]
              score.question_four_notes = responseMap["4_notes"]
              score.question_five_response = responseMap["5"]
              score.question_five_notes = responseMap["5_notes"]
              score.question_six_response = responseMap["6"]
              score.question_six_notes = responseMap["6_notes"]
              score.question_seven_response = responseMap["7"]
              score.question_seven_notes = responseMap["7_notes"]
              score.question_eight_response = responseMap["8"]
              score.question_eight_notes = responseMap["8_notes"]
              score.question_nine_response = responseMap["9"]
              score.question_nine_notes = responseMap["9_notes"]
              score.question_ten_response = responseMap["10"]
              score.question_ten_notes = responseMap["10_notes"]
              score.participant_id = uid
              console.log(activityMap)
              // console.log(typeof activityMap)
              //TODO: Figure out why JSON.stringify returns empty object
              console.log(JSON.stringify(activityMap))
              score.activities = JSON.stringify(activityMap)

              // score.perceived_stress_score = 10
              console.log(JSON.stringify(score.toJson()))
              present("Generating stress score....", 5000)
              try {
                var createdScore = (await createStressScore(score.toJson())).data
                console.log(createdScore.id)
                storage.clear()
                setCounter(1)
                dismiss()
                history.push(`/stress-score/${createdScore.id}`)
              } catch (error) {
                dismiss()
                Toast.show({ text: "Error generating stress score", duration: 'long' })
                console.error(error)
                history.push("/")
              }
              // axios.post("http://localhost:8080/stress-scores",score.toJson(),{headers:{
              //     'Content-Type':'application/json'
              // }}).then((res) => {
              //     console.log("Submitted stress score")
              //     storage.clear()
              // }).catch((err) => {
              //     console.error(err)
              // })


            }}>Next</IonButton>
          }
          {counter < 10 &&
            <IonButton size="default" onClick={async () => {

              let map = responseMap
              map[counter] = response
              setResponseMap(map)
              setCounter(counter + 1)

            }}>Next</IonButton>
          }
        </IonItem>
      </IonContent>
    </IonPage>
  )
}

export default StressQuestionPage;